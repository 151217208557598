<template>
    <div>
        <!-- Main Start -->
        <main class="main">
            <!-- Breadcrumb Start -->
            <div class="breadcrumb-wrap">
                <div class="breadcrumb-wrap">
                    <div class="banner">
                        <img class="bg-img bg-top" src="/assets/images/bg/bg-header-001.jpg" alt="banner" />

                        <div class="container-lg">
                            <div class="breadcrumb-box">
                            <div class="heading-box">
                                <h1> Contáctanos</h1>
                            </div>
                            <ol class="breadcrumb">
                                <li><a href="/">Inicio</a></li>
                                <li>
                                <a href="javascript:void(0)"><i data-feather="chevron-right"></i></a>
                                </li>
                                <li class="current"><a href="#" onclick="return false;">Contáctanos</a></li>
                            </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--  -->
            
            <section class="contact-section">
                <div class="container-lg">
                    <div class="row gy-4 gy-xl-0 gx-0 gx-xl-4 justify-content-center">

                        <div class="col-xl-6 order-2 order-xl-1">
                            <!-- Reply From Section Start -->
                            <div class="replay-form round-wrap-content top-space" id="replaySection" style="background-image: none !important;">
                                <div class="title-box4">
                                <h4 class="heading">Envíanos un mensaje</h4>
                                </div>

                                <form action="javascript:void(0)" class="custom-form form-pill">
                                <div class="input-flex">
                                    <div class="input-group">
                                        <label for="full-name">Nombre </label>
                                        <input type="text" name="full-name" id="full-name">
                                    </div>

                                    <div class="input-group">
                                        <label for="full-name">Apellido</label>
                                        <input type="text" name="full-name" id="full-name">
                                    </div>

                                </div>
                                <div class="input-flex">
                                
                                <div class="input-group">
                                        <label for="phone">Número de teléfono</label>
                                        <input type="text" name="phone" id="phone" >
                                    </div>

                                        
                                <div class="input-group">
                                        <label for="email">Correo electrónico</label>
                                        <input type="email" name="email" id="email" >
                                    </div>
                                    
                                </div>

                                <div class="input-group">
                                <label>Dejar un mensaje</label>
                                <textarea name="" id="" cols="30" rows="4"></textarea>
                                    </div>
                                </form>
                            </div>
                            <!-- Reply From Section End -->
                        </div>
                        <!-- col-12 order-2 order-xl-1 -->
                        <div class="col-xl-6 order-1 order-xl-2 " v-if="config_general">
                            <!-- <div class="address-content round-wrap-content">
                                <div class="title-box4">
                                <h4 class="heading">General: </h4>
                                </div>

                                <div class="steps-wrap">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="steps-box mt-0">
                                                <span><i data-feather="map-pin"></i></span>
                                                <div class="content">
                                                    <h4 class="title-color">Dirección Casa Matriz</h4>
                                                    <p class="content-color">{{ config_general.address }}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-6">
                                            <div class="steps-box mt-0">
                                                <span><i data-feather="map-pin"></i></span>
                                                <div class="content">
                                                    <h4 class="title-color">Horario de Atención</h4>
                                                    <p class="content-color" v-if="config_general.horarios_1">{{ config_general.horarios_1 }}</p>
                                                    <p class="content-color" v-if="config_general.horarios_2">{{ config_general.horarios_2 }}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-6">
                                            <div class="steps-box">
                                                <span><i data-feather="phone"></i></span>
                                                <div class="content">
                                                <h4 class="title-color">Números de Teléfono</h4>
                                                <p class="content-color" v-if="config_general.phone_atencion_al_cliente">Atención al cliente: <a :href="config_general.link_phone_atencion_al_cliente">{{ config_general.phone_atencion_al_cliente }}</a> </p>
                                                <p class="content-color" v-if="config_general.phone_soporte_tecnico">Soporte Técnico: <a :href="config_general.link_phone_soporte_tecnico">{{ config_general.phone_soporte_tecnico }}</a> </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-6">
                                            <div class="steps-box">
                                                <span><i data-feather="mail"></i></span>
                                                <div class="content">
                                                <h4 class="title-color">Email addresses</h4>
                                                <p class="content-color" v-if="config_general.email_atencion_cliente">{{ config_general.email_atencion_cliente }}</p>
                                                <p class="content-color" v-if="config_general.email_soporte_tecnico">{{ config_general.email_soporte_tecnico }}</p>
                                                </div>
                                            </div>
                                        </div>

                                        
                                    </div>
                                </div>
                            </div> -->
                            <div class="address-content round-wrap-content">
                                <div class="title-box4">
                                <h4 class="heading">Datos de contacto</h4>
                                </div>

                                <div class="steps-wrap">
                                <div class="row">
                                    <div class="col-12">
                                    <div class="steps-box mt-0">
                                        <span><img src="/assets/images/Nuevos/location.svg"></span>
                                        <div class="content1">
                                        <h4 class="title-color">Dirección</h4>
                                        <p class="content-color">{{ config_general.address }}</p>
                                        </div>
                                    </div>
                                    </div>

                                    <div class="col-sm-6">
                                    <div class="steps-box">
                                        <span><img src="/assets/images/Nuevos/Phone.svg"></span>
                                        <div class="content1">
                                        <h4 class="title-color">Número de teléfono</h4>
                                        <p class="content-color" v-if="config_general.phone_atencion_al_cliente">Atención al cliente: <a :href="config_general.link_phone_atencion_al_cliente">{{ config_general.phone_atencion_al_cliente }}</a> </p>
                                        </div>
                                    </div>
                                    </div>

                                    <div class="col-sm-6">
                                    <div class="steps-box">
                                        <span><img src="/assets/images/Nuevos/email.svg"></span>
                                        <div class="content1">
                                        <h4 class="title-color">Correo electrónico</h4>
                                        <p class="content-color" v-if="config_general.email_atencion_cliente">{{ config_general.email_atencion_cliente }}</p>
                                        </div>
                                    </div>
                                    </div>

                            
                                </div>
                                </div>
                            </div>
                        </div>
                        
                        <!-- <template v-for="(sucursal, index) in sucursales" :key="index">
                            <div class="col-xl-6 order-1 order-xl-2 py-4">
                                <div class="address-content round-wrap-content">
                                    <div class="title-box4">
                                    <h4 class="heading">Sucursal: {{ sucursal.name }}</h4>
                                    </div>
    
                                    <div class="steps-wrap">
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="steps-box mt-0">
                                                    <span><i data-feather="map-pin"></i></span>
                                                    <div class="content">
                                                        <h4 class="title-color">Dirección :</h4>
                                                        <p class="content-color">{{ sucursal.address }}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-6">
                                                <div class="steps-box mt-0">
                                                    <span><i data-feather="map-pin"></i></span>
                                                    <div class="content">
                                                        <h4 class="title-color">Horario de Atención</h4>
                                                        <p class="content-color" v-if="sucursal.horarios">{{ sucursal.horarios }}</p>
                                                        <p class="content-color" v-if="sucursal.horarios2">{{ sucursal.horarios2 }}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-6">
                                                <div class="steps-box">
                                                    <span><i data-feather="phone"></i></span>
                                                    <div class="content">
                                                      <h4 class="title-color">Números de Teléfono</h4>
                                                      <p class="content-color" v-if="sucursal.phone"><a :href="sucursal.link_phone">{{ sucursal.phone }}</a></p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-6">
                                                <div class="steps-box">
                                                    <span><i data-feather="mail"></i></span>
                                                    <div class="content">
                                                    <h4 class="title-color">Escribenos</h4>
                                                    <p class="content-color" v-if="sucursal.email">{{ sucursal.email }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template> -->

                    </div>
                </div>
            </section>

            <!-- Map Section Start -->
            <div class="map-section">
                <div class="row g-0">
                    <div class="col-12 p-0">
                        <div class="location-map">
                        <iframe
                            class="map-iframe"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3876.6609888431194!2d-89.27844448517035!3d13.678366490394984!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f632e5260fded15%3A0x7f83ec4a7d644894!2sZona%20Digital!5e0!3m2!1ses-419!2ssv!4v1625849379918!5m2!1ses-419!2ssv"
                        ></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Map Section End -->
        </main>
        <!-- Main End -->
    </div>
</template>
<script>
import myApi from "../../../plugins/axios";
export default {
    name: 'ContactoPanel',
    data() {
        return {
            aviso_marca: null,
            config_general: null,
            sucursales: [],
        }
    },
    methods: {
        async listConfiguration(){
            const resp = await myApi.get('ecommerce/config_aviso_politica');
            console.log(resp);
            this.config_general = resp.data.config_general;
            this.sucursales = resp.data.sedes;
            setTimeout(() => {
                this.reloadMyJs();
            }, 50);
        },
        reloadMyJs(){
            const script2 = document.createElement('script');
            script2.src = '../../assets/js/feather/feather.min.js';
            document.body.appendChild(script2);
            script2.onload = () => {
                console.log('archivo.js cargado 2');
            }
        },
    },
    mounted() {
        this.listConfiguration();
    },
}
</script>