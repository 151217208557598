<template>
    <div>
        <main class="main">

            <div class="breadcrumb-wrap" v-if="!categorie_route && !tag_route">
                <div class="banner">
                    <img class="bg-img bg-top" src="/assets/images/inner-page/banner-p.jpg" alt="banner" />

                    <div class="container-lg">
                        <div class="breadcrumb-box">
                            <div class="heading-box">
                                <h1>Blog</h1>
                            </div>
                            <ol class="breadcrumb">
                                <li><a href="/">Inicio</a></li>
                                <li>
                                    <a href="javascript:void(0)"><i data-feather="chevron-right"></i></a>
                                </li>
                                <li class="current"><a href="/blog">Blog</a></li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div class="breadcrumb-wrap" v-if="categorie_route">
                <div class="banner">
                    <img class="bg-img bg-top" src="/assets/images/inner-page/banner-p.jpg" alt="banner" />

                    <div class="container-lg">
                        <div class="breadcrumb-box">
                            <div class="heading-box">
                                <h1>{{ categorie_route }}</h1>
                            </div>
                            <ol class="breadcrumb">
                                <li><a href="/">Inicio</a></li>
                                <li>
                                    <a href="javascript:void(0)"><i data-feather="chevron-right"></i></a>
                                </li>
                                <li class="current"><a href="/blog">Blog</a></li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div class="breadcrumb-wrap" v-if="tag_route">
                <div class="banner">
                    <img class="bg-img bg-top" src="/assets/images/inner-page/banner-p.jpg" alt="banner" />

                    <div class="container-lg">
                        <div class="breadcrumb-box">
                            <div class="heading-box">
                                <h1>{{ tag_route }}</h1>
                            </div>
                            <ol class="breadcrumb">
                                <li><a href="/">Inicio</a></li>
                                <li>
                                    <a href="javascript:void(0)"><i data-feather="chevron-right"></i></a>
                                </li>
                                <li class="current"><a href="/blog">Blog</a></li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <section class="ratio2_3 blog-padding">
                <div class="container-lg">
                    <div class="row">
                        <div class="col-lg-9 order-1 order-lg-1 ratio_36">
                            <div class="blog-wrap">
                                <div class="row g-3 g-xxl-4">
                                    <template v-for="(blog, index) in blogs" :key="index">
                                        <div class="col-sm-6 col-lg-4">
                                            <div class="blog-box blog-grid">
                                                <router-link :to="'/blog/'+blog.slug" class="img-box">
                                                    <img class="bg-img" :src="blog.imagen" alt="blog" />
                                                </router-link>
                                                <div class="content-box">
                                                    <span><i data-feather="clock"> </i>{{ blog.created_format }}</span>
                                                    <router-link :to="'/blog/'+blog.slug"> <h5 class="truncate-2">{{ blog.title }}</h5> 
                                                    </router-link>
                                                    <!-- <a href="blog-detail.html"> -->
                                                        <p class="truncate-2">
                                                            {{ blog.description_text_plain }}
                                                        </p>
                                                    <!-- </a> -->

                                                    <div class="share-box">
                                                        <!-- <div class="sender-box">
                                                        
                                                        </div> -->
                                                        <div class="action-box">
                                                            <router-link :to="'/blog/'+blog.slug" class="btn btn-solid btn-sm replay-btn">Ver</router-link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 order-3 order-lg-2 blog-show">
                            <div class="sidebar-inner sticky">
                                <div class="search-box reverse" style="display: flex;align-items: center;width: 100%;">
                                    <input class="form-control" type="search" v-model="search" @keyup.enter="searchText" style="width: 100%;" placeholder="Titulo blog..." />
                                    <span class="search" @click="searchText()">
                                        <i data-feather="search"></i>
                                    </span>
                                </div>

                                <div class="row g-3 g-lg-4">
                                    <div class="col-sm-6 col-lg-12 order-2 order-lg-1">
                                        <div class="sidebar-box">
                                            <div class="title-box4">
                                                <h4 class="heading">Categories </h4>
                                            </div>
                                            <ul class="catagories-side">
                                                <template v-for="(categorie, index) in categories" :key="index">
                                                    <li>
                                                        <router-link :to="'/blog/categoria/'+categorie.title">
                                                            <span>
                                                                <i data-feather="arrow-right"></i>
                                                                {{ categorie.title }}
                                                                </span>
                                                            <span class="notification">{{ categorie.count_blogs }}</span>
                                                        </router-link>
                                                    </li>
                                                </template>
                                            </ul>
                                        </div>
                                    </div>

                                    <!-- <div class="col-sm-6 col-lg-12 order-3 order-lg-2">
                                        <div class="sidebar-box">
                                            <div class="title-box4">
                                                <h4 class="heading">Ultimas entradas </h4>
                                            </div>
                                            <div class="post-wrap">
                                                <template v-for="(blog_destac, index) in blog_destacados" :key="index">
                                                    <router-link :to="'/blog/'+blog_destac.slug" class="post-box">
                                                        <div class="img-box">
                                                            <img :src="blog_destac.blog_imagen" alt="post" />
                                                        </div>
                                                        <div class="content-box">
                                                            <h5>{{ blog_destac.title }}</h5>
                                                            <span>{{ blog_destac.created_format }}</span>
                                                        </div>
                                                    </router-link>
                                                </template>
                                                
                                            </div>
                                        </div>
                                    </div> -->

                                    <div class="col-lg-12 order-1 order-lg-4">
                                        <div class="sidebar-box">
                                            <div class="title-box4">
                                                <h4 class="heading">Etiquetas </h4>
                                            </div>
                                            <ul class="tags">
                                                <template v-for="(etiqueta, index) in etiqueta_options" :key="index">
                                                    <li>
                                                        <router-link :to="'/blog/tag/'+etiqueta.name"> {{ etiqueta.name }} </router-link>
                                                    </li>
                                                </template>
                                                
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <!-- Pagination Start -->
                    <PaginationG :pagination="{
                        per_page: per_page,
                        total: total,
                        total_pages: total_pages
                    }" :maxVisibleButtons="maxVisibleButtons" :current-page="currentPage"
                        @pagechanged="listBlogs" />
                    <!-- Pagination End -->
                </div>
            </section>

        </main>
    </div>
</template>
<script>
import myApi from '../../../plugins/axios'
import PaginationG from '../../PaginationG.vue'
// import VueMeta from 'vue-meta'
import { useMeta } from 'vue-meta'

export default {
    name: 'BlogList',
    data() {
        return {
            blogs: [],
            categories: [],
            blog_destacados: [],
            // PAGINACIÓN
            currentPage: 1,
            per_page: 1,
            total: 0,
            total_pages: 1,
            maxVisibleButtons: 5,
            // 
            search: '',
            categorie_route: null,
            // 
            etiqueta_options: [],
            tag_route: null,
        }
    },
    components: {
        PaginationG
    },
    methods: {
        searchText() {
            this.listBlogs();
        },
        async listBlogs(page = 1) {
            // let data = {
            // }
            this.currentPage = page;
            const resp = await myApi.get(
                "ecommerce/blogs?page=" + this.currentPage +
                "&search=" + this.search + 
                "&categorie=" + (this.categorie_route ? this.categorie_route : '')+
                "&tag_route=" + (this.tag_route ? this.tag_route : ''),
            );
            console.log(resp);
            this.blogs = [];

            this.blogs = resp.data.blogs.data;

            this.per_page = resp.data.per_page;
            this.total = resp.data.total;
            this.total_pages = resp.data.total_pages;
            this.maxVisibleButtons = this.total_pages > 4 ? 5 : this.total_pages;

            setTimeout(() => {
                this.reloadMyJs();
            }, 50);
        },
        async config_all() {
            const resp = await myApi.get(
                "ecommerce/blogs/config_all/"
            );
            console.log(resp);
            this.categories = resp.data.categories;
            this.blog_destacados = resp.data.blog_destacado;
            this.etiqueta_options = resp.data.tags;
        },
        reloadMyJs() {
            // const detail_product = document.createElement("script");
            // detail_product.src = "../../assets/js/myjs/detailproduct.js";
            // document.body.appendChild(detail_product);
            // detail_product.onload = () => {
            //     console.log("archivo.js cargado detail_product");
            // };
            var script10 = document.createElement('script');
            script10.src = '../../assets/js/script.js';
            document.body.appendChild(script10);
            script10.onload = () => {
                console.log('archivo.js cargado 10');
            }
            const script4 = document.createElement('script');
            script4.src = '../../assets/js/swiper-slider/swiper-custom.min.js';
            document.body.appendChild(script4);
            script4.onload = () => {
                console.log('archivo.js cargado 4');
            }
            const script2 = document.createElement("script");
            script2.src = "../../assets/js/feather/feather.min.js";
            document.body.appendChild(script2);
            script2.onload = () => {
                console.log("archivo.js cargado 2");
            };
        },
    },
    created() {
        this.categorie_route = this.$route.params.categorie;
        this.tag_route = this.$route.params.tag;
    },
    mounted() {
        // <meta property="og:site_name" content="Linda Ojo"> vue-socialmedia-share
        // <meta property="og:title" content="Linda Ojo's Personal website and Blog">
        // <meta property="og:description" content="Articles on frontend development written by Linda Ojo, Frontend Developer">
        // <meta property="og:image" content="logo.png">
        // <meta property="og:url" content="https//www.lindaojo.com">
        useMeta({
            title: 'Blogs',
            htmlAttrs: { lang: 'en', amp: true },
            meta: [
                // {
                //     property: 'og:site_name',
                //     content: 'Blogs | ZONA DIGITAL',
                //     vmid: 'og:site_name'
                // },
                // {
                //     property: 'og:title',
                //     content: 'Blogs | ZONA DIGITAL',
                //     vmid: 'og:title'
                // },
                // {
                //     property: 'og:description',
                //     content: 'Blog relacionados con los productos que puedes encontrar en nuestra tienda',
                //     vmid: 'og:description'
                // },
                // {
                //     property: 'og:url',
                //     content: process.env.VUE_APP_FRONTED+'blog',
                //     vmid: 'og:url'
                // }
            ],
        })
        this.listBlogs();
        this.config_all();
        this.$router.afterEach((to, from, next) => {
            this.categorie_route = this.$route.params.categorie;
            this.tag_route = this.$route.params.tag;
            setTimeout(() => {
                this.listBlogs();
            }, 50);
            next()
        })
    },
}
</script>
<style>
.blog-wrap .avatar-img {
    display: flex !important;
    align-items: center !important;
    width: 100px !important;
    border-radius: initial !important;
}</style>