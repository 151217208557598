import store from '../plugins/store'

function register_guest (PRODUCT) {
    console.log(PRODUCT);
    return {
        title: PRODUCT.title ,
        imagen: PRODUCT.image,
        uniqd: PRODUCT.uniqd ,
        slug: PRODUCT.slug ,
        avg_rating: PRODUCT.avg_rating,
        reviews_count: PRODUCT.reviews_count,
        categorie_first: PRODUCT.categorie_first,
        categorie_second: PRODUCT.categorie_second,
        categorie_third: PRODUCT.categorie_third,
        precio_general: PRODUCT.precio_general,
        id: PRODUCT.id,
        product_id: PRODUCT.id,
    }
}

function register_client (PRODUCT) {
    console.log(PRODUCT);
    return {
        user_id: PRODUCT.user_id,
        title: PRODUCT.title ,
        imagen: PRODUCT.image,
        product_id: PRODUCT.id,
        uniqd: PRODUCT.uniqd,
        slug: PRODUCT.slug ,
        avg_rating: PRODUCT.avg_rating,
        reviews_count: PRODUCT.reviews_count,
        categorie_first: PRODUCT.categorie_first,
        categorie_second: PRODUCT.categorie_second,
        categorie_third: PRODUCT.categorie_third,
        precio_general: PRODUCT.precio_general,
        id: PRODUCT.id,
    }
}

function update (WISH) {
    return {
        user_id: WISH.user_id,
        title: WISH.product.title ,
        imagen: WISH.product.image,
        product_id: WISH.product_id,
        uniqd: WISH.product.uniqd,
        slug: WISH.product.slug ,
        avg_rating: WISH.product.avg_rating,
        reviews_count: WISH.product.reviews_count,
        categorie_first: WISH.product.categorie_first,
        categorie_second: WISH.product.categorie_second,
        categorie_third: WISH.product.categorie_third,
        precio_general: WISH.product.precio_general,
        product: WISH.product,
        id: WISH.id,
    }
}

export default {
    register_guest: (PRODUCT) => {
       return register_guest(PRODUCT);
    },
    register_client: (PRODUCT) => {
        return register_client(PRODUCT);
    },
    update: (CART) => {
        return update(CART);
    },
    add: (WISHLIST,PRODUCTY,emitter,notify,cookies) => {
        let INDEX = WISHLIST.findIndex(product => product.uniqd == PRODUCTY.uniqd);
        
        if(!store.getters.loggedIn){
        if(WISHLIST.length >= 5){
            notify({
                title: "Solo se puede agregar 5 productos como maximo",
                // text: `Tu producto '${productT.title}' ya se encuentra en la lista de comparación!`,
                type:'error',
                duration: 3500,
            });
            return;
            }
          let data = register_guest(PRODUCTY);
            if(INDEX != -1){
                notify({
                    title: "El producto ya se encuentra en la lista de deseo",
                    // text: `Tu producto '${PRODUCTY.title}' ya se encuentra en la lista de deseo!`,
                    type:'warn',
                    duration: 3500,
                });
                return;
            }else{
                WISHLIST.push(data);
            }
          cookies.set('wishlist',WISHLIST);
          emitter.emit("wish-list-product", WISHLIST);
          notify({
            title: "Producto agregado a lista de deseos",
            // text: `Tu producto '${PRODUCTY.title}' se agrego a lista de deseo!`,
            duration: 3500,
            type:'success'
        });
        }else{
          var data = {};
          if(INDEX != -1){
            notify({
                title: "El producto ya se encuentra en la lista de deseo",
                // text: `Tu producto '${PRODUCTY.title}' ya se encuentra en la lista de deseo!`,
                type:'warn',
                duration: 3500,
            });
          }else{
            PRODUCTY.user_id = store.state.user.id;
            data = register_client(PRODUCTY);
            emitter.emit('save-wish-list', data);
          }
        }
    },
}