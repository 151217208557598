<template>
  <div class="">
    <!-- <HeaderSection /> -->
    <!-- Main Start -->
    <main class="main">
      <!-- Home Banner Section Start -->
      <section class="home-slider-common ratio_40 p-0">
        <div class="swiper-container home-slider">
          <div class="swiper-wrapper">

            <template v-for="(banner, index) in banners" :key="index">
              <div class="swiper-slide" v-if="banner.type == 1 && !isMobile()">
                <!-- <div class="banner">
                  <div> -->
                <!--  v-lazy="{ src: banner.imagen, loading: '/assets/svg_load/image-preloader.svg' }" :src="banner.imagen" -->
                <template v-if="banner.links">
                  <a :href="banner.links">
                    <img class="bg-img img-fluid" :src="banner.imagen" alt="slide 3" />
                  </a>
                </template>
                <img v-if="!banner.links" :src="banner.imagen" class="bg-img img-fluid" alt="slide 1" />
                <!-- </div>
                </div> -->
              </div>
            </template>
            <template v-for="(banner, index) in banners" :key="index">
              <div class="swiper-slide" v-if="banner.type == 2 && isMobile()">
                <!-- <div class="banner">
                  <div> -->
                <!--  v-lazy="{ src: banner.imagen, loading: '/assets/svg_load/image-preloader.svg' }" :src="banner.imagen" -->
                <template v-if="banner.links">
                  <a :href="banner.links">
                    <img class="bg-img img-fluid" :src="banner.imagen" alt="slide 3" />
                  </a>
                </template>
                <img v-if="!banner.links" :src="banner.imagen" class="bg-img img-fluid" alt="slide 1" />
                <!-- </div>
                </div> -->
              </div>
            </template>
            <template v-if="banners.length == 0">
              <div class="swiper-slide">
                <!-- <div class="banner">
                  <div> -->
                <img src="/assets/svg_load/image-preloader.svg" class="bg-img img-fluid" alt="slide 1" />
                <!-- </div>
                </div> -->
              </div>
            </template>
            <!-- Slide Start -->

          </div>

          <!-- Flechas de navegación -->
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
          <!-- Puntos de paginación -->
          <div class="swiper-pagination"></div>

        </div>
      </section>
      <!-- Home Banner Section End -->

      <!-- Categories Section Start -->
      <div class="shop-page">
        <div class="container-lg">
          <div class="catagories-section mt-0">
            <!-- v-bind="settings" :breakpoints="breakpointsCategories"-->
            <!-- :href="'/shop/categorie/'+categorie.id+'?text='+categorie.name"  :class="{'active_categorie': categorie_selected == categorie.id}"-->
            <!-- <Carousel v-bind="settings" :breakpoints="breakpointsCategories">
              <Slide v-for="(categorie, index) in categories"
                  :key="index">
                <div class="carousel__item">

                  <div class="catagories catagories-shop" >
                      <a 
                      href="javascript:void(0)"
                      class="img-categories"
                      :data-url="'/shop/categorie/'+categorie.id+'?text='+categorie.name"
                      >
                      <div class="img-wrap">
                        <img :src="categorie.miniatura" alt="zd" />
                      </div>
                      <div class="btn-box">
                        <span class="catagories-name"> {{ categorie.name }} </span>
                      </div>
                    </a>
                  </div>

                </div>
              </Slide>

              <template #addons>
                <Navigation />
              </template>
            </Carousel> :class="{'active_categorie': categorie_selected == categorie.id}"-->

            <div class="swiper-box">
              <div class="swiper categorias-home-slider">
                <div class="swiper-wrapper">
                  <template v-for="(categorie, index) in categories" :key="index">
                    <div class="swiper-slide">
                      <div class="catagories catagories-shop">
                        <!-- :href="'/shop/categorie/'+categorie.id+'?text='+categorie.name" -->
                        <span @click="getUrlNav('/shop/categorie/' + categorie.id + '?text=' + categorie.name)">
                          <div class="img-wrap">
                            <img :src="categorie.miniatura" alt="zd" />
                          </div>
                          <div class="btn-box">
                            <span class="catagories-name"> {{ categorie.name }} </span>
                          </div>
                        </span>

                      </div>
                    </div>
                  </template>
                </div>
              </div>
              <div class="swiper-button">
                <button class="swiper-next">
                  <i data-feather="arrow-left"></i>
                </button>
                <button class="swiper-prev"><i data-feather="arrow-right"></i></button>
              </div>
            </div>

          </div>
        </div>
        <!-- </div> -->
        <!-- Categories Section End -->

        <!-- Collection Banner 5 Start v-lazy="{ src: imagen_1_b, loading: '/assets/svg_load/image-preloader.svg' }"-->
        <section class="special-product-4">
          <div class="container-lg">


            <div class="row g-2 g-lg-3 g-xxl-4 ratio_asos" v-if="config_general">
              <div class="col-md-6">
                <a :href="config_general && config_general.imagen_1 ? config_general.imagen_1 : '/shop'"
                  class="img-box big-ratio">
                  <img class="bg-img" :src="imagen_1_b" alt="banner" />
                  <!-- :src="imagen_1_b" -->
                </a>
              </div>

              <div class="col-md-6">
                <div class="row g-2 g-lg-3 g-xxl-4">
                  <div class="col-6">
                    <a :href="config_general && config_general.imagen_2 ? config_general.imagen_2 : '/shop'"
                      class="img-box sm-ratio">
                      <img class="bg-img" :src="imagen_2_b" alt="banner" />
                    </a>
                    <!-- :src="imagen_2_b" -->
                  </div>

                  <div class="col-6">
                    <a :href="config_general && config_general.imagen_3 ? config_general.imagen_3 : '/shop'"
                      class="img-box sm-ratio">
                      <img class="bg-img" :src="imagen_3_b" alt="banner" />
                      <!-- :src="imagen_3_b" -->
                    </a>
                  </div>

                  <div class="col-12">
                    <a :href="config_general && config_general.imagen_4 ? config_general.imagen_4 : '/shop/categorie/2?text=PC%20Gamer&categorie_third_id=639&categorie_s_second_id=637'"
                      class="img-box banner-ratio">
                      <img class="bg-img" :src="imagen_4_b" alt="banner" />
                    </a>
                    <!-- :src="imagen_4_b"  -->
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-2 g-lg-3 g-xxl-4 ratio_asos" v-if="!config_general">
              <div class="col-md-6">
                <a href="#" onclick="return false;" class="img-box big-ratio">
                  <img class="bg-img" src="/assets/svg_load/image-preloader.svg" alt="banner" />
                </a>
              </div>

              <div class="col-md-6">
                <div class="row g-2 g-lg-3 g-xxl-4">
                  <div class="col-6">
                    <a href="#" onclick="return false;" class="img-box sm-ratio">
                      <img class="bg-img" src="/assets/svg_load/image-preloader.svg" alt="banner" />
                    </a>
                  </div>

                  <div class="col-6">
                    <a href="#" onclick="return false;" class="img-box sm-ratio">
                      <img class="bg-img" src="/assets/svg_load/image-preloader.svg" alt="banner" />
                    </a>
                  </div>

                  <div class="col-12">
                    <a href="#" onclick="return false;" class="img-box banner-ratio">
                      <img class="bg-img" src="/assets/svg_load/image-preloader.svg" alt="banner" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- Collection Banner 5 End -->

        <!-- New Arrived Section Start -->
        <section class="ratio_asos nuevos-carrusel">
          <div class="container-lg">
            <div class="title-box">
              <h2 class="unique-heading">⚡ Productos Nuevos ⚡</h2>
              <button class="see-all">
                <router-link :to="'/shop/tag?text=nuevos'">Ver todos ➜</router-link>
              </button>
            </div>
            <!-- <Carousel v-bind="settings" :breakpoints="breakpoints">
            <Slide v-for="(product, index) in products"
                :key="index">
              <div class="carousel__item">
                <CardProductA v-if="product" :product="product"></CardProductA>
              </div>
            </Slide>

            <template #addons>
              <Navigation />
            </template>
          </Carousel> -->
            <div class="swiper-box product-home-1">
              <div class="swiper product-slider-home">
                <div class="swiper-wrapper">

                  <div class="swiper-slide" v-for="product in products" :key="product._id">
                    <!-- <div class="product-card  option-bottom" v-if="product">
                    <h1>{{ product.title }}</h1>
                  </div> -->
                    <CardProductA v-if="product" :product="product" :is_not_detail="true" :type_card="1"></CardProductA>
                  </div>

                </div>
              </div>
              <div class="swiper-button" v-if="is_data_load && products.length > 5">
                <button class="swiper-next">
                  <i data-feather="arrow-left"></i>
                </button>
                <button class="swiper-prev"><i data-feather="arrow-right"></i></button>
              </div>
            </div>

          </div>
        </section>

        <section class="ratio_asos recomendados-carrusel">
          <div class="container-lg">
            <div class="title-box">
              <h2 class="unique-heading">👍 Productos Recomendados</h2>
              <button class="see-all">
                <router-link :to="'/shop/tag?text=destacado'">Ver todos ➜</router-link>
              </button>
            </div>
            <div class="swiper-box product-home-2">
              <div class="swiper product-slider-home1">
                <div class="swiper-wrapper">
                  <div class="swiper-slide" v-for="product in product_recomendados" :key="product._id">
                    <CardProductA v-if="product" :product="product" :is_not_detail="true" :type_card="1"></CardProductA>
                  </div>
                </div>
              </div>
              <div class="swiper-button" v-if="is_data_load && product_recomendados.length > 5">
                <button class="swiper-next">
                  <i data-feather="arrow-left"></i>
                </button>
                <button class="swiper-prev"><i data-feather="arrow-right"></i></button>
              </div>
            </div>

            <!-- <Carousel v-bind="settings" :breakpoints="breakpoints">
            <Slide v-for="(product, index) in product_recomendados"
                :key="index">
              <div class="carousel__item">
                <CardProductA v-if="product" :product="product"></CardProductA>
              </div>
            </Slide>

            <template #addons>
              <Navigation />
            </template>
          </Carousel> -->

          </div>
        </section>

        <section class="ratio_asos hot-carrusel">
          <div class="container-lg">
            <div class="title-box">
              <h2 class="unique-heading">🔥 Los Más Hot De Zona</h2>
              <button class="see-all">
                <router-link :to="'/shop/best-sellers'">Ver todos ➜</router-link>
              </button>
            </div>

            <!-- <Carousel v-bind="settings" :breakpoints="breakpoints">
            <Slide v-for="(product, index) in product_hots_c"
                :key="index">
              <div class="carousel__item">
                <CardProductA v-if="product" :product="product"></CardProductA>
              </div>
            </Slide>

            <template #addons>
              <Navigation />
            </template>
          </Carousel> -->
            <div class="swiper-box product-home-3">
              <div class="swiper product-slider-home2">
                <div class="swiper-wrapper">
                  <div class="swiper-slide" v-for="product in product_hots_c" :key="product._id">
                    <CardProductA v-if="product" :product="product" :is_not_detail="true" :type_card="1"></CardProductA>
                  </div>
                </div>
              </div>
              <div class="swiper-button" v-if="is_data_load && product_hots_c.length > 5">
                <button class="swiper-next">
                  <i data-feather="arrow-left"></i>
                </button>
                <button class="swiper-prev"><i data-feather="arrow-right"></i></button>
              </div>
            </div>


          </div>
        </section>

        <div class="loader_div" v-if="loaderOffers">
          <div class="title-box">
            <h2 class="unique-heading">Cargando...</h2>
          </div>
          <div class="loader">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <template v-for="(product_offerta, index) in product_hots_ofertas" :key="index">
          <section class="border oferta ratio_asos" :style="{ 'background': product_offerta.color_tag + '!important' }">
            <div class=flex-marcas>
              <h3 class="title-oferta">
                <!-- :style="{'color': product_offerta.color_tag +'!important' }"  -->
                <a :href="'/discount/' + product_offerta.slug">{{ product_offerta.title }}</a>
              </h3>
              <div class="back-to2">
                <button class="btn">
                  <a class="back-shop2" :href="'/discount/' + product_offerta.slug">Ver todos ➜</a>
                </button>
              </div>
            </div>
            <div class="row ofertas-flex">

              <div class="col-md-6 oferta-img">
                <!-- :src="product_offerta.imagen" -->
                <!-- <img  
                  v-lazy="{ src: product_offerta.imagen }"
                   alt=""> -->
                <img :src="product_offerta.imagen" alt="">
                <!-- <ProgressiveImage 
                   class="img-fluid bg-img" style="min-height: 332px;"  :src="product_offerta.imagen" /> -->
              </div>

              <div class="col-md-6 slider-ofertas">
                <!-- <Carousel v-bind="settings" :breakpoints="breakpointsOffert">
                  <Slide v-for="(product_off, index) in product_offerta.products.data" 
                    :key="index">
                    <div class="carousel__item">
                      <CardProductA v-if="product_off" :product="product_off"></CardProductA>
                    </div>
                  </Slide>

                  <template v-if="product_offerta.products.data.length > 3" #addons>
                    <Navigation />
                  </template>
  </Carousel> -->
                <div :class="'swiper-box ofertas-home-' + index">

                  <div :class="'swiper product-slider-offertas-' + index">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide" v-for="(product_off, index) in product_offerta.products.data"
                        :key="index">
                        <CardProductA v-if="product_off" :product="product_off" :is_not_detail="true" :type_card="1">
                        </CardProductA>
                      </div>
                    </div>
                  </div>

                  <div class="swiper-button" v-if="product_offerta.products.data.length > 3">
                    <button class="swiper-next">
                      <i data-feather="arrow-left"></i>
                    </button>
                    <button class="swiper-prev"><i data-feather="arrow-right"></i></button>
                  </div>
                </div>

              </div>
            </div>
          </section>
        </template>

        <section class="ratio_asos marcas marcas-carrusel">
          <div class="title-box">
            <h2 class="unique-heading">Nuestras marcas</h2>
            <button class="see-all">
              <router-link :to="'/shop/brands'">Ver todos ➜</router-link>
            </button>
          </div>
          <div class="swiper marca-slider">
            <div class="swiper-wrapper">
              <!-- <div >
                          <a :href="'/shop/brands/'+marca.id+'?text='+marca.name" class="brand-box"><img
                          class="img-fluid bg-white"
                          :src="marca.imagen"
                          alt="brand"></a>
                      </div> 
                    :src="marca.banner" 
                    :src="marca.imagen"
                    -->

              <template v-for="(marca, index) in marca_descatado" :key="index">
                <div class="swiper-slide">
                  <router-link :to="'/shop/brands/' + marca.id + '?text=' + marca.name" v-if="marca.banner">
                    <img :src="marca.banner" height="100%" width="100%" alt="">
                  </router-link>

                  <router-link :to="'/shop/brands/' + marca.id + '?text=' + marca.name" v-if="!marca.banner">
                    <img :src="marca.imagen" height="100%" width="100%" alt="">
                  </router-link>
                </div>
              </template>

            </div>
          </div>
        </section>

        <section class="videos">
          <div class="container-lg">
            <!-- <div class="title-box4">
                <h4 class="heading font-2xl">Videos (reels/historias)</h4>
            </div> -->

            <div class="row justify-content-center">

              <!-- <template
                      v-for="(video, index) in listVideo"
                      :key="index"
                      >
                <div class="col-3">
                  <a href="javascript:void(0)" @click="openVideoProduct(video)" data-bs-toggle="modal" data-bs-target="#viewModalVideo">
                      <video height="360" style="width: 100%;">
                          <source :src="video.video" type="video/mp4">
                      </video>
                    </a>
                </div>
              </template> -->

              <div class="col-12">
                <Carousel v-bind="settings" :breakpoints="breakpointsVideos">
                  <Slide v-for="(video, index) in listVideo" :key="index">
                    <div class="carousel__item">
                      <!-- <a href="javascript:void(0)" > -->
                      <!-- <video height="360" style="width: 100%;">
                            <source :src="video.video" @click="openVideoProduct(video)" data-bs-toggle="modal" data-bs-target="#viewModalVideo" type="video/mp4">
                        </video> -->
                      <div v-html="video.embed"></div>
                      <!-- </a> -->
                    </div>
                  </Slide>

                  <template #addons>
                    <Navigation />
                  </template>
                </Carousel>
              </div>

            </div>

            <div class="follow-us">
              <p>Síguenos en Instagram <a href="https://www.instagram.com/zonadigitalsv/" target="_blank">
                  <img src="../assets/icons/svg/social/inta.svg" alt="Instagram"> zonadigitalsv </a></p>
            </div>

          </div>
        </section>
      </div>
      <section class="service-section services-flex">
        <div class="container-lg">
          <div class="row g-3 g-md-4 g-lg-0">
            <div class="col-6 col-lg-3">
              <div class="service-box">
                <div class="media">
                  <img class="img-fluid" src="/assets/images/features/envios-rapidos-a-todo-el-pais.webp"
                    alt="Envios rápidos a todo el pais" />
                </div>
              </div>
            </div>

            <div class="col-6 col-lg-3">
              <div class="service-box">
                <div class="media">
                  <router-link to="/shop/tag?text=nuevo">
                    <img class="img-fluid" src="/assets/images/features/productos-nuevos-cada-semana.webp"
                      alt="Nuevos productos todas las semanas" />
                  </router-link>
                </div>
              </div>
            </div>

            <div class="col-6 col-lg-3">
              <div class="service-box">
                <div class="media">
                  <img class="img-fluid" src="/assets/images/features/compra-segura.webp"
                    alt="Compras en línea seguras con certificado SSL" />

                </div>
              </div>
            </div>

            <div class="col-6 col-lg-3">
              <div class="service-box">
                <div class="media">
                  <a :href="config_general_dinamic.link_phone_soporte_tecnico">
                    <img class="img-fluid" src="/assets/images/features/soporte-tecnico.webp" alt="Soporte técnico" />
                  </a>
                </div>
              </div>
            </div>



          </div>
        </div>
      </section>
    </main>
    <!-- Main End -->
    <VideoProduct />
    <DetailProduct />
    <div class="cookie-bar cookies-bar-1 left-cookies d-none">
      <img src="assets/png/cookie.png" alt="cookies" />
      <div class="content">
        <h5>Cookies Consent</h5>
        <p>This website use cookies to ensure you get the best experience on our website.</p>
        <div class="cookie-buttons">
          <button class="btn-solid btn-sm mb-line cookies-accept">Accept <i class="arrow"></i></button>
          <a href="javascript:void(0)" class="btn-solid btn-sm btn-outline cookies-accept">Learn more</a>
        </div>
      </div>
    </div>
    <div class="tap-to-top-box hide">
      <button class="tap-to-top-button"><i data-feather="chevrons-up"></i></button>
    </div>
  </div>
</template>

<script>
import myApi from '../plugins/axios'
import DetailProduct from '../views/guest/modal/DetailProduct.vue'
import CardProductA from '../components/cards/CardProductA.vue'

import store from '../plugins/store'
import VideoProduct from './modal/VideoProduct.vue'
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'

export default {
  name: 'HomeSection',
  components: {
    DetailProduct,
    CardProductA,
    VideoProduct,
    // 
    Carousel,
    Slide,
    Navigation,
    // 
  },
  props: {
    msg: String
  },
  data() {
    return {
      products: [],
      categories: [],
      CARTS: [],
      WISHLIST: [],
      user: null,
      banners: [],
      config_general: null,
      // 
      imagen_1_b: '/assets/svg_load/banner-preloader.svg',//'/assets/images/banner/Banner_v2.png',
      imagen_2_b: '/assets/svg_load/banner-preloader.svg',//'/assets/images/banner/elige_tusilla.png',
      imagen_3_b: '/assets/svg_load/banner-preloader.svg',//'/assets/images/banner/PC_BUILD.png',
      imagen_4_b: '/assets/svg_load/banner-preloader.svg',//'/assets/images/banner/Banner_principal.png',
      // 
      product_hots_c: [],
      product_recomendados: [],
      product_hots_ofertas: [],
      listVideo: [],
      marca_descatado: [],
      // 
      product_loades: [1, 2, 3, 4, 5],
      INDEXBDS: [],
      // 
      settings: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      config_general_dinamic: {
        link_phone_soporte_tecnico: ''
      },
      breakpointsCategories: {
        380: {
          itemsToShow: 1.5,
          snapAlign: 'start',
        },
        700: {
          itemsToShow: 3.5,
          snapAlign: 'start',
        },
        1024: {
          itemsToShow: 5,
          snapAlign: 'start',
        },
      },
      breakpoints: {
        700: {
          itemsToShow: 3.5,
          snapAlign: 'center',
        },
        1024: {
          itemsToShow: 5,
          snapAlign: 'start',
        },
      },
      breakpointsOffert: {
        700: {
          itemsToShow: 2,
          snapAlign: 'center',
        },
        1024: {
          itemsToShow: 3,
          snapAlign: 'start',
        },
      },
      breakpointsVideos: {
        575: {
          itemsToShow: 2,
          snapAlign: 'center',
        },
        767: {
          itemsToShow: 2,
          snapAlign: 'center',
        },
        1200: {
          itemsToShow: 3,
          snapAlign: 'start',
        },
      },
      is_data_load: false,
      loaderOffers:false
    }
  },
  methods: {
    todetalle() {
      this.$router.push({
        name: 'DetailProduct',
      })
    },
    getUrlNav(URL) {
      window.location.href = URL;
    },
    reloadMyJs() {
      const script_myjs = document.createElement('script');
      script_myjs.src = '../../assets/js/myjs/myjs.js';
      document.body.appendChild(script_myjs);
      script_myjs.onload = () => {
        console.log('archivo.js cargado script_myjs');
      }
      const script2 = document.createElement('script');
      script2.src = '../../assets/js/feather/feather.min.js';
      document.body.appendChild(script2);
      script2.onload = () => {
        console.log('archivo.js cargado 2');
      }
    },
    reloadMyHomeJs() {
      const script_myjs = document.createElement('script');
      script_myjs.src = '../../assets/js/myjs/sliderhome.js';
      document.body.appendChild(script_myjs);
      script_myjs.onload = () => {
        console.log('archivo.js cargado script_myjs');
      }
      const script6 = document.createElement("script");
      script6.src = "../../assets/js/myjs/loadredirect.js";
      document.body.appendChild(script6);
      script6.onload = () => {
        console.log("archivo.js cargado 7");
      };
    },
    async allProducts() {
      this.is_data_load = false;
      let resp = await myApi.get('ecommerce/home');
      console.log(resp);
      this.is_data_load = true;
      this.products = resp.data.products.data;
      this.product_hots_c = resp.data.product_hots_c.data;
      this.product_recomendados = resp.data.product_recomendados.data;
      // console.log(this.config_general);
      // if(!this.config_general){
      this.allBanners();
      // }
      this.allHomeOfertas();
      if (this.categories.length == 0) {
        this.categories = resp.data.categories;
        this.marca_descatado = resp.data.marcas_destacadas;
        // this.$cookies.set('categories_i',this.categories);
        let data = {
          dat: {
            name: 'categories_i',
            data: JSON.stringify(this.categories),
          },
          name: 'ecommerce',
        }
        await this.$store.dispatch('saveDat', data);
        let data2 = {
          dat: {
            name: 'marca_descatado_i',
            data: JSON.stringify(this.marca_descatado),
          },
          name: 'ecommerce',
        }
        console.log("A");
        await this.$store.dispatch('saveDat', data2);
        // this.$cookies.set('marca_descatado_i',this.marca_descatado);
      }
      setTimeout(() => {
        this.reloadMyHomeJs()
      }, 50);
    },
    async allBanners() {
      let videos_img_b_i = this.INDEXBDS.find((idb) => idb.name == 'videos_img_b_i');
      let banners_img_b_i = this.INDEXBDS.find((idb) => idb.name == 'banners_img_b_i');

      let resp = await myApi.get('ecommerce/banners');
      console.log(resp);
      // this.$cookies.set('banners_img_b',this.banners);
      this.banners = resp.data.banners;
      this.config_general_dinamic = resp.data.config_general_dinamic;
      if (!banners_img_b_i) {
        let data2 = {
          dat: {
            name: 'banners_img_b_i',
            data: JSON.stringify(this.banners),
          },
          name: 'ecommerce',
        }
        // console.log("B");
        await this.$store.dispatch('saveDat', data2);
      }

      // if(!this.config_general){
      if (resp.data.config_general.imagen_1_b) {
        this.imagen_1_b = resp.data.config_general.imagen_1_b;
      }
      if (resp.data.config_general.imagen_2_b) {
        this.imagen_2_b = resp.data.config_general.imagen_2_b;
      }
      if (resp.data.config_general.imagen_3_b) {
        this.imagen_3_b = resp.data.config_general.imagen_3_b;
      }
      if (resp.data.config_general.imagen_4_b) {
        this.imagen_4_b = resp.data.config_general.imagen_4_b;
      }
      this.config_general = resp.data.config_general;
      // this.$cookies.set('config_general_img_b',this.config_general);
      // }

      if (!videos_img_b_i) {
        this.listVideo = resp.data.videos;
        let data3 = {
          dat: {
            name: 'videos_img_b_i',
            data: JSON.stringify(this.listVideo),
          },
          name: 'ecommerce',
        }
        // console.log("B");
        await this.$store.dispatch('saveDat', data3);
      }
      // this.$cookies.set('videos_img_b',this.listVideo);
      setTimeout(() => {
        this.reloadMyHomeJs()
        this.reloadMyJs();
        this.config_general = resp.data.config_general;
      }, 50);
    },
    openVideoProduct(video = 1) {
      console.log(video);
      this.emitter.emit('product-video-modal', video);
    },
    async allHomeOfertas() {
      this.loaderOffers=true
      let resp = await myApi.get('ecommerce/home_ofertas');
      console.log(resp);
      this.product_hots_ofertas = resp.data.product_hots_d;
      setTimeout(() => {
        this.reloadMyHomeJs();
      }, 60);
      this.loaderOffers=false
    },
    actualizarMetaetiquetas(titulo, descripcion, imagen, url) {
      const ogTitle = document.querySelector('meta[property="og:title"]');
      const ogDescription = document.querySelector('meta[property="og:description"]');
      const ogImage = document.querySelector('meta[property="og:image"]');
      const ogUrl = document.querySelector('meta[property="og:url"]');

      ogTitle.content = titulo;
      ogDescription.content = descripcion ? descripcion : '';
      if (imagen) {
        ogImage.content = imagen;
      }
      ogUrl.content = url;
    },
    isMobile() {
      if (window.innerWidth <= 767 || screen.width <= 767) {
        return true;
      }
      else {
        return false;
      }
    }
  },
  async created() {
    await this.$store.dispatch('getDats', 'ecommerce');
    // console.log(this.$store.state.dats);
    // if (this.isMobile()) {
    //     console.log("MOBILE")
    // }else {
    //   console.log("desktok")
    // }
  },
  async mounted() {

    // this.allBanners();
    // this.$cookies.set('EXAMPLE',{ id:1, name:'Journal',session:'25j_7Sl6xDq2Kc3ym0fmrSSk2xV2XkUkX' });
    // PARA EL CARRITO
    // let banners_img_b = this.$cookies.get("banners_img_b") ? this.$cookies.get("banners_img_b") : null;
    let config_general_img_b = null;//this.$cookies.get("config_general_img_b") ? this.$cookies.get("config_general_img_b") : null;
    // let videos_img_b = this.$cookies.get("videos_img_b") ? this.$cookies.get("videos_img_b") : null;
    // console.log(banners_img_b,config_general_img_b,videos_img_b);banners_img_b &&  && videos_img_b
    if (config_general_img_b) {
      // this.banners = banners_img_b;
      // this.listVideo = videos_img_b;
      this.config_general = config_general_img_b;
      if (this.config_general.imagen_1_b) {
        this.imagen_1_b = this.config_general.imagen_1_b;
      }
      if (this.config_general.imagen_2_b) {
        this.imagen_2_b = this.config_general.imagen_2_b;
      }
      if (this.config_general.imagen_3_b) {
        this.imagen_3_b = this.config_general.imagen_3_b;
      }
      if (this.config_general.imagen_4_b) {
        this.imagen_4_b = this.config_general.imagen_4_b;
      }
      setTimeout(() => {
        this.reloadMyHomeJs()
        this.reloadMyJs();
      }, 500);
    }
    this.actualizarMetaetiquetas(
      'Zona Digital - PC Gamers | Computadoras | Accesorios y Más',
      'Lo más nuevo en PC Gaming, Hardware, Accesorios, Gadgets, Celulares, Consolas de Videojuegos y otros. Solo las mejoras marcas.',
      null, 'https://zonadigitalsv.com/'
    );
    // let categories_i = this.$cookies.get("categories_i") ? this.$cookies.get("categories_i") : null;
    // let marca_descatado_i = this.$cookies.get("marca_descatado_i") ? this.$cookies.get("marca_descatado_i") : null;
    // 
    this.INDEXBDS = this.$store.state.dats;
    setTimeout(() => {
      let categories_i = this.INDEXBDS.find((idb) => idb.name == 'categories_i');
      let marca_descatado_i = this.INDEXBDS.find((idb) => idb.name == 'marca_descatado_i');
      let banners_img_b_i = this.INDEXBDS.find((idb) => idb.name == 'banners_img_b_i');
      let videos_img_b_i = this.INDEXBDS.find((idb) => idb.name == 'videos_img_b_i');
      if (categories_i && marca_descatado_i && videos_img_b_i) {// && banners_img_b_i
        this.categories = JSON.parse(categories_i.data);
        this.marca_descatado = JSON.parse(marca_descatado_i.data);
        this.banners = JSON.parse(banners_img_b_i.data);
        this.listVideo = JSON.parse(videos_img_b_i.data);
      }
    }, 60);

    setTimeout(() => {
      this.allProducts();
    }, 50);

    this.emitter.on('cart-shop', data => {
      this.CARTS = data;
    });
    this.emitter.emit("cart-list", {});

    //PARA LA LISTA DE DESEO
    this.emitter.on('wish-list-product', data => {
      this.WISHLIST = data;
    });
    this.emitter.emit("wish-list-list", {});

    this.user = store.state.user;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
} */
.marca-slider img {
  /* height: 100% !important; */
}
</style>
