<template>
    <!-- Main Start -->
    <div class="main">
      <section class="page-body p-0">
        <div class="row">
          <div class="order-2 order-lg-1 col-lg-5">
            <div class="content-box">
              <div>
                <h5>Resetear Contraseña </h5>
                <p class="font-md content-color">Actualiza tu nueva contraseña.</p>

                <form action="login.html" class="custom-form form-pill">
                  <!-- <div class="input-box1">
                    <label for="password">Current Password</label>
                    <div class="icon-input">
                      <input class="form-control" type="password" required name="password" id="password" />
                      <img class="showHidePassword" src="assets/icons/svg/eye-1.svg" alt="eye" />
                    </div>
                  </div> -->
                  <div class="input-box1">
                    <label for="password2">Nuevo Contraseña</label>
                    <div class="icon-input">
                      <input class="form-control" type="password" required name="password" v-model="password" id="password2" />
                      <img class="showHidePassword" src="assets/icons/svg/eye-1.svg" alt="eye" />
                    </div>
                  </div>
                  <div class="input-box1">
                    <label for="password3">Confirmar Contraseña</label>
                    <div class="icon-input">
                      <input class="form-control" type="password" required name="password" v-model="repet_password" id="password3" />
                      <img class="showHidePassword" src="assets/icons/svg/eye-1.svg" alt="eye" />
                    </div>
                  </div>

                  <button type="button" @click="resetPassword()" v-if="!loading" class="btn-solid rounded-pill line-none">Guardar<i class="arrow"></i></button>
                  <div class="loader-5 center" v-if="loading"><span></span></div>
                  <a href="/" class="btn-solid rounded-pill line-none btn-outline mt-3 d-flex justify-content-center">Inicio <i class="arrow"></i></a>
                </form>
                <a href="/login" class="backto-link font-default content-color">Regresar Login</a>
              </div>
            </div>
          </div>
          <div class="order-1 order-lg-2 col-lg-7">
            <div class="img-box">
              <img class="bg-img" src="/assets/images/bg/bg-zd-000.jpg" alt="banner" />
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- Main End -->
</template>
<script>
import myApi from '../../../plugins/axios'
export default {
    name:'ResetPassword',
    data(){
        return {
            code:null,
            cookie: null,
            password: null,
            repet_password: null,
            loading: false,
        }
    },
    methods: {
        async resetPassword(){
            if(!this.password ||
            !this.repet_password){
                this.$notify({
                        title: "VALIDACIÓN",
                        text: `NECESITAS INGRESAR TODOS LOS CAMPOS`,
                        duration: 3500,
                        type:'error'
                    });
                return;
            }

            if(this.password != this.repet_password){
                this.$notify({
                        title: "VALIDACIÓN",
                        text: `NECESITAS QUE LAS CONTRASEÑAS SEAN IGUALES`,
                        duration: 3500,
                        type:'error'
                    });
                return;
            }

            try {
                this.loading = true;
                const resp = await myApi.post(
                    "ecommerce/reset_password/",
                    {
                        email: this.cookie.email,
                        password: this.password,
                    }
                );
                this.loading = false;
                console.log(resp);
                if(resp.data.message == 403){
                    this.$notify({
                        title: "VALIDACIÓN",
                        text: `${resp.data.message_text}`,
                        duration: 3500,
                        type:'error'
                    });
                }else{
                    this.$notify({
                        title: "RESETAR CONTRASEÑA",
                        text: `LA CONTRASEÑA HA SIDO CAMBIADO SATISFACTORIAMENTE`,
                        duration: 3500,
                    });
                    this.$cookies.remove("forgot_password");
                    this.$cookies.remove("code_password");
                    setTimeout(() => {
                        this.$router.push({
                            name: 'Login'
                        });
                    }, 50);
                }
            } catch (error) {
                this.$notify({
                    title: "INTERNO",
                    text: `SURGIO UN PROBLEMA EN EL SERVIDOR , COMUNICARSE CON EL SOPORTE DE LA PAGINA`,
                    duration: 3500,
                    type:'error'
                });
                console.log(error);
            }
        }
    },
    created(){
        this.cookie = this.$cookies.get("code_password") ? this.$cookies.get("code_password") : null;
        if(!this.cookie){
            this.$router.push({
                name: 'CodeOtp',
            });
        }
        console.log(this.cookie);
    },
}
</script>