import { createApp } from 'vue'
import App from './App.vue'
import router from './routers';
import store from './plugins/store';
// import axios from './plugins/axios'
import VueCookies from 'vue-cookies'
import Notifications from '@kyvg/vue3-notification'
import mitt from 'mitt'
import { createMetaManager } from 'vue-meta'
import VueSocialSharing from 'vue-social-sharing'
import VueLazyLoad from 'vue3-lazyload'
// import VueLazyload from 'vue-lazyload'
// import VModal from 'vue-js-modal'
import "vue-progressive-image/dist/style.css"; // <--- very important!
import VueProgressiveImage from 'vue-progressive-image'
// import Lingallery from 'lingallery';
// import * as GmapVue from 'gmap-vue'
// import GAuth from 'vue3-google-oauth2'
// const gauthOption = {
//   clientId: '820389707210-noeljepidcc5jh7h7tu3d91rsh7ra99r.apps.googleusercontent.com',
//   scope: 'profile email',
//   prompt: 'consent',
//   fetch_basic_profile: true,
// }
// const gAuthOptions = { clientId: '820389707210-noeljepidcc5jh7h7tu3d91rsh7ra99r.apps.googleusercontent.com', scope: 'email', prompt: 'consent', fetch_basic_profile: false }
// import '../public/assets/js/bootstrap/bootstrap.bundle.min.js'
// import '../public/assets/js/feather/feather.min.js'
// import '../public/assets/js/swiper-slider/swiper-bundle.min.js'
// import '../public/assets/js/swiper-slider/swiper-custom.min.js'
// import '../public/assets/js/timer.js'
// import '../public/assets/js/sticky-header.js'
// // import '../public/assets/js/active-class.js'
// import '../public/assets/js/wow.js'
// import '../public/assets/js/wow-custom.js'
// import '../public/assets/js/script.js'

// <%= BASE_URL %>assets/js/bootstrap/bootstrap.bundle.min.js
// <%= BASE_URL %>assets/js/feather/feather.min.js
// <%= BASE_URL %>assets/js/swiper-slider/swiper-bundle.min.js
// <%= BASE_URL %>assets/js/swiper-slider/swiper-custom.min.js
// <%= BASE_URL %>assets/js/timer.js
// <%= BASE_URL %>assets/js/sticky-header.js
// <%= BASE_URL %>assets/js/active-class.js
// <%= BASE_URL %>assets/js/wow.js
// <%= BASE_URL %>assets/js/wow-custom.js
// <%= BASE_URL %>assets/js/script.js

const app = createApp(App)
// createApp(App).config
const emitter = mitt()
app.config.globalProperties.emitter = emitter
// app.config.globalProperties.modal = VModal
app.use(router)
// .use(VModal, {
//     dynamicDefaults: {
//       draggable: true,
//       resizable: true,
//       height: 'auto'
//     }
// })
.use(store)
.use(VueCookies)
.use(VueLazyLoad, {
    loading: '/assets/svg_load/image-preloader.svg',
    error:  '/assets/banner/banner_error.jpg',
    // lifecycle: {
    //   loading: (el) => {
    //     console.log('loading', el)
    //   },
    //   error: (el) => {
    //     console.log('error', el)
    //   },
    //   loaded: (el) => {
    //     console.log('loaded', el)
    //   }
    // }
})
// .use(VueLazyload, {
//   preLoad: 1.3,
//   error: '/assets/banner/banner_error.jpg',
//   loading: '/assets/banner/banner_load.jpg',
//   attempt: 1
// })
.use(VueProgressiveImage)
// .component('lingallery', Lingallery)
.use(createMetaManager())
.use(VueSocialSharing)
// .use(GAuth, gAuthOptions)
// .use(GmapVue, {
//     load: {
//         key: 'AIzaSyA6MRhgLWpKf7XfNNUP3H9yz-ts9R3hvD8',
//         libraries: 'places', // This is required if you use the Autocomplete plugin

//     },
//     installComponents: true
// })
.use(Notifications)
// .use(axios)
.mount('#app')
