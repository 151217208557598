<template>
    <div class="modal view-product fade" id="viewTerminosPcBuild" tabindex="-1" aria-labelledby="viewTerminosPcBuild" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" style="width: 600px !important;">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="addNewAddressLabel">Confirmación del Carrito </h5>
                <!-- <span class="close-modal" data-bs-dismiss="modal"><i data-feather="x"></i></span> -->
            </div>
            <div class="modal-body">
                <span class="close-modal d-none d-md-block" data-bs-toggle="modal" data-bs-target="#viewTerminosPcBuild"><i data-feather="x"></i></span>
                <div class="row gy-4 g-md-0 justify-content-center">
                    <div class="col-12">
                        <div class="d-flex align-items-center justify-content-center">
                            <label for="acepte_terminos" class="inside mb-0 mx-2">
                               Al momento de confirmar la acción, los productos que esten en el carrito y que coincidan con los del Pc Builder van hacer eliminados, para agregar los nuevos.
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="btn-box">
                    <button type="button" class="btn btn-primary rounded-pill w-50" 
                    data-bs-toggle="modal" data-bs-target="#viewTerminosPcBuild" @click="selectedStar(1)">Agregar al Carrito</button>

                    <!-- <button type="button" class="btn btn-outline rounded-pill" 
                    data-bs-dismiss="modal" aria-label="Close" @click="selectedStar() ">Cerrar</button> -->
                </div>
            </div>
        </div>
      </div>
    </div>
</template>
<script>
export default {
    name: 'ModalDeleteCart',
    data(){
        return {
            product_selected_modal:null,
            acepte_terminos:null,
        }
    },
    methods:{
        selectedStar(val = null){
            this.emitter.emit("acept-terminos", val);
        },
    },
    mounted(){
    }
}
</script>