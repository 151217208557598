<template>
    <Transition name="modal">
      <div v-if="show" class="modal-mask">
        <div class="modal-container modal-tracking-pedido">
          <div class="modal-header">
            <slot name="header">default header</slot>
          </div>
  
          <div class="modal-body modal-body-s" >
            <slot name="body">default body</slot>
          </div>
  
          <div class="modal-footer">
            <slot name="footer">
              <!-- default footer -->
              <button
                class="modal-default-button btn btn-dark"
                @click="$emit('close')"
              >Cerrar </button>
              <!-- <button
                class="modal-default-button btn btn-primary"
                @click="$emit('confirmation')"
              >Confirmar </button> -->
            </slot>
          </div>
        </div>
      </div>
    </Transition>
  </template>
  <script>
  export default {
    props: {
      show: Boolean
    }
  }
  </script>
  <style>
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    transition: opacity 0.3s ease;
  }
  
  .modal-container {
    margin: auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
  }
  .modal-tracking-pedido{
    width: 1250px;
  }
  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
  }
  
  .modal-body-s {
    margin: 20px 0;
    height: 400px;
    color: black !important;
  }
  .modal-body-s .title-color {
      color: black !important;
  }
  .modal-body-s .cart-table thead tr th {
      color: black !important;
  }
  .modal-default-button {
    float: right;
  }
  
  /*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */
  
  .modal-enter-from {
    opacity: 0;
  }
  
  .modal-leave-to {
    opacity: 0;
  }
  
  .modal-enter-from .modal-container,
  .modal-leave-to .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  </style>