<template>
    <div>
        <!-- Main Start -->
        <main class="main">
            <!-- Breadcrumb Start -->
            <div class="breadcrumb-wrap">
                <div class="banner">
                <img class="bg-img bg-top" src="/assets/images/bg/bg-header-001.jpg" alt="banner" />

                <div class="container-lg">
                    <div class="breadcrumb-box">
                    <div class="heading-box" v-if="!marca_selected">
                        <h1>{{ slug_marcas ? slug_marcas : ''}}</h1>
                    </div>
                    <div class="heading-box fondo-blanco" v-if="marca_selected">
                        <img :src="marca_selected.imagen" alt="">
                    </div>
                    <!-- <ol class="breadcrumb">
                        <li><a href="/">Inicio</a></li>
                        <li>
                        <a href="javascript:void(0)"><i data-feather="chevron-right"></i></a>
                        </li>
                        <li class="current"><a href="#" onclick="return false;">Marca</a></li>
                    </ol> -->
                    </div>
                </div>
                </div>
            </div>
            <!-- Breadcrumb End -->
            <FilterProduct v-if="marca_id" :marca_id="marca_id" :show_categorie="1"></FilterProduct>
        </main>
        <!-- Main End -->

    </div>
</template>
<script>
import FilterProduct from './layouts/FilterProduct.vue'
import myApi from '../../plugins/axios'
import { useMeta } from 'vue-meta'

export default {
    name: 'FilterMarcaD',
    components:{
        FilterProduct,
    },
    data(){
        return {
            slug_marcas: null,
            marca_id: null,
            marca_selected: null,
        }
    },
    methods: {
        makeTitle(slug) {
            var words = slug.split('-');
            for (var i = 0; i < words.length; i++) {
                var word = words[i];
                words[i] = word.charAt(0).toUpperCase() + word.slice(1);
            }
            return words.join(' ');
        }
    },
    created(){
        this.slug_marcas = this.$route.query.text;
        this.marca_id = this.$route.params.id;
    },
    async mounted(){
        let TITLE_MARCA = this.makeTitle(this.slug_marcas);
        this.useMetaT = useMeta({
            title: TITLE_MARCA,
            htmlAttrs: { lang: 'en', amp: true },
            meta: [],
        })
        const resp = await myApi.get(
            "ecommerce/marcas/"+this.marca_id);
        this.marca_selected = resp.data.marca;
        setTimeout(() => {
            window.scrollTo({
                top: 100,
                behavior: 'smooth'
            });
        }, 50);
    }
}
</script>