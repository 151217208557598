<template>
    <div>
        <!-- Main Start -->
        <main class="main">
            <!-- Breadcrumb Start -->
            <div class="breadcrumb-wrap">
                <div class="breadcrumb-wrap">
                    <div class="banner">
                        <img class="bg-img bg-top" src="/assets/images/bg/bg-header-001.jpg" alt="banner" />

                        <div class="container-lg">
                            <div class="breadcrumb-box">
                            <div class="heading-box">
                                <h1> Aviso de Marca</h1>
                            </div>
                            <ol class="breadcrumb">
                                <li><a href="/">Inicio</a></li>
                                <li>
                                <a href="javascript:void(0)"><i data-feather="chevron-right"></i></a>
                                </li>
                                <li class="current"><a href="#" onclick="return false;">Aviso de Marca</a></li>
                            </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--  -->
            
            <section class="info-condiciones">
                <div class="box-condiciones">
                    <div class="img-mascota-box">
                        <img src="https://zonadigitalsv.com/assets/images/brands/zeta-brands.svg" alt="">
                    </div>
                    <div v-html="aviso_marca"></div>
                </div>
            </section>
        </main>
        <!-- Main End -->
    </div>
</template>
<script>
import myApi from "../../../plugins/axios";
export default {
    name: 'CondicionYOferta',
    data() {
        return {
            aviso_marca: null,
        }
    },
    methods: {
        async listConfiguration(){
            const resp = await myApi.get('ecommerce/config_aviso_politica');
            console.log(resp);
            this.aviso_marca = resp.data.config_general.aviso_marca;
            // this.aviso_marca = resp.data.config_general.aviso_marca;
            // this.garantia = resp.data.config_general.garantia;
            // this.privacidad = resp.data.config_general.privacidad;
            // this.delivery = resp.data.config_general.delivery;

        },
    },
    mounted() {
        this.listConfiguration();
    },
}
</script>