<template>
  <div @mouseleave="hideMegaMenu()" class="megamenu-wrapper">
    <div class="main-categories">
      <div
        v-for="(departament, index) in categories"
        :key="index"
        @mouseover="changeMainCategory(departament.id)"
        :class="['main-category', isActive(departament.id) ? 'active' : '']"
      >
        <div class="icon"><img src="../../assets/main-category.svg" /></div>
        <div class="label">
          <a :href="'/shop/categorie/'+departament.id+'?text='+departament.name"
                  > {{ departament.name }}</a>
        </div>
      </div>
    </div>
    <div class="sub-categories">

        <!-- <div v-for="(departament, index) in categories" :key="index" class="sub-category">
          <div class="item-nav" v-for="(categorie, index2) in departament.categories" :key="index2" >
            <div class="group-title">
                <img :src="categorie.miniatura" alt="">
                <a class="title-sub"> {{ categorie.name }}</a>
            </div>
            <div class="item-categ">
              <template v-for="(sub_categorie, index3) in categorie.categories" :key="index3">
                <a href="#">{{ sub_categorie.name }}</a>
              </template>
            </div>
          </div>
        </div> -->
        <template v-if="departament_selected">
          <div v-for="(categorie, index2) in departament_selected.categories" :key="index2" class="sub-category">
            <div class="item-nav" >
              <div class="group-title">
                  <img :src="categorie.miniatura" alt="">
                  <a class="title-sub" 
                  :href="'/shop/categorie/'+departament_selected.id+'?text='+categorie.name+'&categorie_second_id='+categorie.id"
                  > {{ categorie.name }}</a>
              </div>
              <div class="item-categ">
                <template v-for="(sub_categorie, index3) in categorie.categories" :key="index3">
                  <a 
                  :href="'/shop/categorie/'+departament_selected.id+'?text='+sub_categorie.name+'&categorie_third_id='+sub_categorie.id+'&categorie_s_second_id='+categorie.id"
                  >{{ sub_categorie.name }}</a>
                </template>
              </div>
            </div>
          </div>
        </template>

    </div>
  </div>
</template>

<script>

export default {
  name: "MegaMenu",
  data() {
    return {
      activeMainCategory: 1,
      lastX: 0,
      lastY: 0,
      direction: "",
      throttle: false,
      categories:[],
      departament_selected: null,
    };
  },
  props: ['categorie_cookies'],
  methods: {
    hideMegaMenu() {
      this.emitter.emit("hide-mega-menu");
    },
    isActive(key) {
      return this.activeMainCategory === key;
    },
    mouseDirection(e) {
      if (!this.throttle) {
        this.throttle = true;
        let theta = Math.abs(
          (180 * Math.atan2(e.pageY - this.lastY, e.pageX - this.lastX)) /
            Math.PI
        );
        this.direction = theta > 75 ? "vertical" : "horizontal";
        this.lastX = e.pageX;
        this.lastY = e.pageY;
        setTimeout(() => {
          this.throttle = false;
        }, 50);
      }
    },
    changeMainCategory(index) {
      if (this.direction === "vertical") {
        this.activeMainCategory = index;
        this.departament_selected = this.categories.find(item => item.id == index);
      }
    },
  },
  mounted() {
    window.addEventListener("mousemove", this.mouseDirection);
    this.categories = this.categorie_cookies;
    this.departament_selected = this.categories[0];
    this.activeMainCategory = this.departament_selected.id;
  },
  beforeUnmount() {
    window.removeEventListener("mousemove", this.mouseDirection);
  },
};
</script>

<style scoped>
.megamenu-wrapper {
  border: 1px solid #A4A4A4;
  border-top: none;
  max-width: 1400px;
  margin: 0 auto;
  height: 500px;
  background: #F8F8F8;
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: ". .";
}
.main-categories {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 0px 0px;
  border-right: 1px solid #A4A4A4;
}
.main-category {
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  padding-left: 30px;
  background: #E6ECFF;
  font-weight:bold;
}
.main-category:not(:last-of-type) {
  border-bottom: 1px solid #A4A4A4;
}
.main-category .icon {
  margin-right: 15px;
}
.main-category .label a{
  color: #000;
}
.main-category.active {
  background: #0D6EFD;
    color: #fff;
}
.sub-categories {
  background: #F7F9FF;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    ". . .  "
    ". . .  ";
}
.sub-category {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sub-category .label {
  margin-top: 15px;
}
.main-category .icon {
    display: none;
}
/* esto ya lo tengo en el css*/
.item-nav img {
    width: 75px;
    height:75px;
}
a.title-sub {
    font-size: 14px!important;
    font-weight: 700;
    line-height: 16px;
    /* color: #0D6EFD; */
}
.item-categ {
    display: flex;
    flex-direction: column;
}
.item-categ a:hover {
    color: #0D6EFD;
}
.item-nav {
    display: flex;
    align-items: flex-start;
    padding: 20px;
    column-gap: 10px;
     width: 235px;
     flex-direction: column;
}
.item-categ a {
    font-size: 15px;
    color:#a9a9a9;
     text-decoration:none!important;
}
.item-categ a::before {
    content: '• ';
}
.group-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 5px;
}

.megamenu-wrapper{
    border: 1px solid #A4A4A4;
    border-top: none;
    max-width: 1400px;
    margin: 0 auto;
    height: 500px;
    background: #F8F8F8;
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-template-rows: 1fr;
    gap: 0 0;
    grid-template-areas: ". .";
    position: absolute;
    z-index: 99;
    left: 0;
    right: 0;
}
.sub-categories{
    background: #F7F9FF;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0 0;
    grid-template-areas:
        ". . . "
        ". . . ";
    grid-template-rows: none;
    align-items: flex-start;
}
.main-category.active .label a{
    color: #fff!important;
}
.main-category:not(:last-of-type) {
    border-bottom: 1px solid #c2c2c28a;
}
a.title-sub{
    color: #000;
}
.item-categ {
    padding-top: 15px;
}
a.title-sub:hover {
    color: #3B73F1;
}
</style>