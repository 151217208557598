<template>
    <!-- Main Start -->
    <main class="main">
      <!-- 404 Section Start -->
      <section class="page-not-found">
        <div class="container">
          <div class="row gx-md-2 gx-0 gy-md-0 gy-3">
            <div class="col-md-8 m-auto">

              <div class="page-image error-404">
                <h1 class="title-error">Ups, página no encontrada.</h1>
                <img src="/assets/images/404/zeta-404.svg" class="img-fluid blur-up lazyload" alt="Error 404" />
                <a href="/" class="btn-solid mb-line">Volver al inicio </a>
              </div>

            </div>
          </div>
        </div>
      </section>
      <!-- 404 Section End -->
    </main>
    <!-- Main End -->
</template>
<script>
export default {
    name: 'PageNotFound',
}
</script>