<template>
    <div>
        <main class="main">
            <div class="breadcrumb-wrap ofertas-bg">
                <div class="banner3"
                    style="background: url('/assets/images/servicios/bg-servicioss-3.webp'); background-color:#1d233d">
                    <div class="container-lg">
                        <div class="breadcrumb-box">
                            <div class="heading-box hero-img">
                                <h1  class="servicios-title">Servicios & Productos</h1>
                            </div>
                            <h3 class="servicios-sub">En Zona Digital, nos especializamos en ofrecer<br> soluciones tecnológicas integrales que se adaptan a las necesidades
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
            <section class="servicios">
                <div class="container">
                    <div class="row">

                            <div class="col-lg-4 col-md-4 col-sm-12 ">
                                <div class="card card-special text-white bgcolor-5 mb-3">
                                    <div class="card-body">
                                        <h2 class="card-title animate-charcter2">Soluciones de Hardware</h2>
                                        <p class="card-text">Proveemos una amplia gama de productos tecnológicos, seleccionados para cumplir con los más altos estándares de calidad y rendimiento.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-4 col-sm-12 ">
                                <div class="card card-special text-white bgcolor-5 mb-3">
                                    <div class="card-body">
                                        <h2 class="card-title animate-charcter2">Marcas Reconocidas</h2>
                                        <p class="card-text">Trabajamos con proveedores de renombre mundial, garantizando la disponibilidad de productos de marcas líderes en la industria.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-4 col-sm-12 ">
                                <div class="card card-special text-white bgcolor-5 mb-3 ">
                                    <div class="card-body">
                                        <h2 class="card-title animate-charcter2">Remodelación de Oficinas</h2>
                                        <p class="card-text">Ofrecemos servicios personalizados de re- modelación de oficinas, diseñados para optimizar espacios de trabajo con tecnología avanzada.</p>
                                    </div>
                                </div>
                            </div>
                    
                    </div>
                </div>
            </section>
            
            <section class="clientes bgcolor-5">
                <div class="container">
                    <div class="row">
                        <h3 class="clientes-title">Nuestros clientes </h3>
                        <p class="clientes-sub">A lo largo de nuestra historia, hemos tenido el privilegio de trabajar con diversas organizaciones que confían en nuestras soluciones tecnológicas. Algunos de nuestros clientes incluyen:</p>
                        <!-- Logos clientes -->
                    </div>
                    <div class="special-product-4">
                        <div class="container-lg">
                            <div class="row g-2 g-lg-3 g-xxl-4 ratio_asos">

                                <div class="col-4">
                                    <a href="#" onclick="return false;" class="img-box sm-ratio">
                                        <img class="bg-img-s" src="/assets/images/servicios/clientes-01.webp" alt="Unilever" />
                                    </a>
                                </div>

                                <div class="col-4">
                                    <a href="#" onclick="return false;" class="img-box sm-ratio">
                                        <img class="bg-img-s" src="/assets/images/servicios/clientes-02.webp" alt="Fundación Gloria Kriete" />
                                    </a>
                                </div>

                                <div class="col-4">
                                    <a href="#" onclick="return false;" class="img-box sm-ratio">
                                        <img class="bg-img-s" src="/assets/images/servicios/clientes-03.webp" alt="Edesal" />
                                    </a>
                                </div>

                                <div class="col-4">
                                    <a href="#" onclick="return false;" class="img-box sm-ratio">
                                        <img class="bg-img-s" src="/assets/images/servicios/clientes-04.webp" alt="OMS" />
                                    </a>
                                </div>

                                <div class="col-4">
                                    <a href="#" onclick="return false;" class="img-box sm-ratio">
                                        <img class="bg-img-s" src="/assets/images/servicios/clientes-05.webp" alt="Telus" />
                                    </a>
                                </div>

                                <div class="col-4">
                                    <a href="#" onclick="return false;" class="img-box sm-ratio">
                                        <img class="bg-img-s" src="/assets/images/servicios/clientes-06.webp" alt="Ministerio de obras publicas" />
                                    </a>
                                </div>

                                <div class="col-4">
                                    <a href="#" onclick="return false;" class="img-box sm-ratio">
                                        <img class="bg-img-s" src="/assets/images/servicios/clientes-07.webp" alt="Corinca" />
                                    </a>
                                </div>

                                <div class="col-4">
                                    <a href="#" onclick="return false;" class="img-box sm-ratio">
                                        <img class="bg-img-s" src="/assets/images/servicios/clientes-08.webp" alt="Cajamarca" />
                                    </a>
                                </div>

                                <div class="col-4">
                                    <a href="#" onclick="return false;" class="img-box sm-ratio">
                                        <img class="bg-img-s" src="/assets/images/servicios/clientes-09.webp" alt="TexOps" />
                                    </a>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>

    </div>
</template>
<script>
export default {
    
}
</script>