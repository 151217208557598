<template>
    <div>
        <!-- Shop Section Start -->
      <section class="shop-page">
        <div class="container-lg">
          <!-- Top Catagories Section Start -->
          <div class="catagories-section mt-0" v-if="is_tienda">
            <!-- <Carousel v-bind="settings" :breakpoints="breakpoints">
              <Slide v-for="(categorie, index) in categories"
                  :key="index">
                <div class="carousel__item">

                  <div class="catagories catagories-shop" :class="{'active_categorie': categorie_selected == categorie.id}">
                      <a
                      class="img-categories"
                      :href="'/shop/categorie/'+categorie.id+'?text='+categorie.name"
                      >
                      <div class="img-wrap">
                        <img :src="categorie.miniatura" alt="zd" />
                      </div>
                      <div class="btn-box">
                        <span class="catagories-name"> {{ categorie.name }} </span>
                      </div>
                    </a>
                  </div>

                </div>
              </Slide>

              <template #addons>
                <Navigation />
              </template>
            </Carousel> -->
            <div class="swiper-box">
              <div class="swiper categorias-home-slider-p">
                <div class="swiper-wrapper">
                  <template v-for="(categorie, index) in categories" :key="index">
                    <div class="swiper-slide">
                      <div class="catagories catagories-shop" :class="{'active_categorie': categorie_selected == categorie.id}">
                        <!-- :class="{'active_categorie': categorie_selected == categorie.id}" -->
                         <!-- <a :href="'/shop/categorie/'+categorie.id+'?text='+categorie.name">
                          <div class="img-wrap">
                            <img :src="categorie.miniatura" alt="zd" />
                          </div>
                          <div class="btn-box">
                            <span class="catagories-name"> {{ categorie.name }} </span>
                          </div>
                        </a> -->
                        <a :href="'/shop/categorie/'+categorie.id+'?text='+categorie.name">
                            <div class="img-wrap">
                              <img :src="categorie.miniatura" alt="zd" />
                            </div>
                            <div class="btn-box">
                              <span class="catagories-name"> {{ categorie.name }} </span>
                            </div>
                          </a>

                      </div>
                    </div>
                  </template>
                </div>
              </div>
              <div class="swiper-button">
                <button class="swiper-next">
                  <i data-feather="arrow-left"></i>
                </button>
                <button class="swiper-prev"><i data-feather="arrow-right"></i></button>
              </div>
            </div>

          </div>
          <div v-if="categories.length == 0">
            <img :src="'/assets/svg_load/shop-subcategorias-preloader.svg'" alt="Icono SVG" />
          </div>
          <div class="title-search" v-if="slug_product">
            <h5>Resultados de: <span class="blue-title"> {{ slug_product }}</span></h5>
          </div>

          <div class="row gy-4 g-lg-3 g-xxl-4" v-if="kits && kits.length > 0">
            <div class="col-12">
              <div class="container-lg">
                <div class="swiper-box">
                  <div class="swiper kits-slider-home">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide" v-for="product in kits" :key="product._id">
                        <CardKitA v-if="product" :product="product" :is_not_detail="true" :type_card="1"></CardKitA>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-button">
                    <button class="swiper-next">
                      <i data-feather="arrow-left"></i>
                    </button>
                    <button class="swiper-prev"><i data-feather="arrow-right"></i></button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row gy-4 g-lg-3 g-xxl-4">
            <div class="col-4 col-xl-3 sidebar-controll sidebar-responsive">
              <div class="sidebar-inner sticky">
                <div class="back-box d-flex d-lg-none">
                  <span>Back</span>
                  <span><i data-feather="x"></i></span>
                </div>

                <!-- <div class="search-box reverse">
                  <input class="form-control" type="search" placeholder="Search here..." />
                  <span class="search">
                    <i data-feather="search"></i>
                  </span>
                </div> -->

                <div class="row gy-3 gx-0 g-lg-4">
                  <!-- <div class="col-12">
                    <div class="sidebar-box">
                      <div class="title-box4">
                        <h4 class="heading">Applied Filters </h4>
                      </div>
                      <ul class="tags">
                        <li>
                          <a href="javascript:void(0)"> T-Shirt <i data-feather="x"></i> </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)"> Handbags <i data-feather="x"></i></a>
                        </li>
                        <li>
                          <a href="javascript:void(0)"> Trends <i data-feather="x"></i> </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)"> Fashion <i data-feather="x"></i> </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)"> Designer <i data-feather="x"></i> </a>
                        </li>
                      </ul>
                    </div>
                  </div> -->

                  <div class="col-lg-12">
                    <div class="sidebar-box">
                      <div class="title-box4">
                        <h4 class="heading">FILTROS </h4>
                      </div>

                      <div class="range-slider">
                        <div class="price-input">
                          <div class="field">
                            <span>Min <strong class="theme-color"> $</strong></span>
                            <input class="form-control input-min" ref="min_price" @input="minMaskPrice" @keyup="changeRange" type="number" :value="min_price" />
                          </div>
                          <div class="separator">-</div>
                          <div class="field">
                            <span>Max <strong class="theme-color"> $</strong></span>
                            <input class="form-control input-max" ref="max_price" @input="maxMaskPrice" @keyup="changeRange" type="number" :value="max_price" />
                          </div>
                        </div>
                        <div class="slider">
                          <div class="progress" style="left: auto;right: auto;"></div>
                        </div>
                        <div class="range-input">
                          <input type="range" class="range-min" min="0" @change="changeRange" max="2000" value="0" step="100" />
                          <input type="range" class="range-max" min="0" @change="changeRange" max="2000" value="100" step="100" />
                        </div>
                      </div>

                      <div class="filter-option py-3" v-if="!show_categorie || show_marca">
                          <h5>MARCAS</h5>
                          <div class="filter-content">
                            <ul class="filter-check">
                                <template v-for="(marca, index) in marca_options" :key="index">
                                <li>
                                    <label class="checkboxes style-1">
                                        <input type="checkbox" :name="'checked'+marca.marcas_id" @click="selectedMarca(marca)" />
                                        <span class="checkbox__checkmark"></span>
                                        <span class="checkbox__body">{{ marca.marcas_name }} <span> {{ marca.cantidad_productos }}</span>
                                        </span>
                                    </label>
                                </li>
                                </template> 
                            </ul>
                          </div>
                      </div>
                      <div class="filter-option py-3" v-if="show_categorie">
                          <h5>CATEGORIA</h5>
                          <div class="filter-content">
                            <ul class="filter-check">
                                <template v-for="(categorie, index) in categoria_options" :key="index">
                                <li>
                                    <label class="checkboxes style-1">
                                        <input type="checkbox" :name="'checked'+categorie.categories_id" @click="selectedCategorie(categorie)" />
                                        <span class="checkbox__checkmark"></span>
                                        <span class="checkbox__body">{{ categorie.categories_name }} <span> {{ categorie.cantidad_productos }}</span>
                                        </span>
                                    </label>
                                </li>
                                </template> 
                            </ul>
                          </div>
                      </div>
                      <!-- <template v-for="(attribute, index) in attributes" :key="index">
                          <div class="filter-option" v-if="attribute.attributes.length > 0">
                              <h5>{{ attribute.name }}</h5>
                              <div class="filter-content">
                              <ul class="filter-check">
                                  <template v-for="(subattribute, index2) in attribute.attributes" :key="index2">
                                      <li>
                                      <label class="checkboxes style-1">
                                          <input type="checkbox" :name="'checked'+subattribute.id" @click="selectedAttributes(subattribute)" />
                                          <span class="checkbox__checkmark"></span>
                                          <span class="checkbox__body">{{ getAttributeN(subattribute) }}
                                          </span>
                                      </label>
                                      </li>
                                  </template>
                              </ul>
                              </div>
                          </div>
                      </template> -->
                        

                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div class="col-lg-8 col-xl-9">
              <div class="row gy-5 g-lg-3 g-xxl-4">
                

                <div class="col-12 order-1 order-lg-2">
                  <div class="shop-product">
                    

                    <div class="product-tab-content">
                      <!--  -->
                      <div class="nav-inner" v-if="categories_selected">
                        <template v-for="(categorie, index) in categories_selected.categories" :key="index">
                          <div class="item-nav">
                              <div class="group-title">
                                <img :src="categorie.miniatura" alt="">
                                <a class="title-sub" 
                                :href="'/shop/categorie/'+categorie_selected+'?text='+categorie.name+'&categorie_second_id='+categorie.id" 
                                v-if="!categorie_g_selected" >{{ categorie.name }}</a>
                                <!-- @click="categorieSeleted(categorie.id)" -->
                                <a class="title-sub" 
                                :href="'/shop/categorie/'+categorie_selected+'?text='+categorie.name+'&categorie_third_id='+categorie.id
                                +'&categorie_s_second_id='+categories_selected.id" 
                                v-if="categorie_g_selected" >{{ categorie.name }}</a>
                                <!-- @click="subcategorieSeleted(categorie.id)" -->
                              </div>
                              <div class="item-categ">
                                <!-- href="javascript:void(0)"  @click="subcategorieSeleted(sub_categorie.id)"-->
                                <template v-for="(sub_categorie, index2) in categorie.categories" :key="index2">
                                  <a 
                                  :href="'/shop/categorie/'+categorie_selected+'?text='+sub_categorie.name+'&categorie_third_id='+sub_categorie.id
                                    +'&categorie_s_second_id='+categorie.id" 
                                  >{{ sub_categorie.name }}</a>
                                </template>
                              </div>
                          </div>
                        </template>
                      </div>
                      
                      <div class="top-header-wrap">
                        <!-- <button class="filter-btn btn-solid btn-sm mb-line d-flex d-lg-none">Filtro <i class="arrow"></i></button> -->
                        <div class="grid-option-wrap">
                          <div class="results-p">
                            <p>Se han encontrado {{ total ? total : '-' }} productos</p>
                          </div>
                          <button class="filter-btn btn-solid btn-sm mb-line d-flex d-lg-none">Filtros</button>

                          <div class="select-options">
                            <div class="select-menu">
                              <div class="dropdown select-dropdown">
                                <button class="select-showval" id="sortProduct">Ordernar por: <span id="order-page">Todos</span> <i data-feather="chevron-down"></i></button>
                                <ul class="onhover-show-div select-vallist" v-if="loadignquery">
                                  <li class="select-list sorder" @click="tagSelect(1)">Todos</li>
                                  <li class="select-list sorder" @click="tagSelect(2)" v-if="!slug_tags">Nuevo</li>
                                  <!-- <li class="select-list" @click="tagSelect(3)" v-if="!slug_tags">Destacado</li>
                                  <li class="select-list" @click="tagSelect(4)" v-if="!slug_tags">Reingreso</li> -->
                                  <li class="select-list sorder" @click="tagSelect(5)">Precio más bajo</li>
                                  <li class="select-list sorder" @click="tagSelect(6)">Precio más alto</li>
                                </ul>
                              </div>
                              <div class="dropdown select-dropdown small-dropdown">
                                <button class="select-showval" id="featureProduct"><span id="register-page">30 Registros</span> <i data-feather="chevron-down"></i></button>
                                <ul class="onhover-show-div select-vallist" v-if="loadignquery">
                                  <li class="select-list srecord" @click="sizePage(30)">30 Registros</li>
                                  <li class="select-list srecord" @click="sizePage(45)">45 Registros</li>
                                  <li class="select-list srecord" @click="sizePage(60)">60 Registros</li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          
                        </div>
                      </div>

                      <!--  -->
                      <div class="view-option row g-3 g-xl-4 ratio_asos row-cols-2 row-cols-sm-3 row-cols-xl-4 grid-section">
                        <template v-for="(product, index) in products" :key="index">
                            <div>
                              <CardProductA v-if="product" :product="product" :is_not_detail="1"></CardProductA>
                            </div>
                        </template>

                        <div class="col-12" v-if="loadignquery && products.length == 0">
                          <div class="not-found" style="width: 100%;">
                              <p>¡Ups! No he encontrado resultados<br> pero hay mucho más, busca algo nuevo.</p>
                              <img src="/assets/images/product/zeta-notfound.svg">
                          </div>
                        </div>

                        <div class="col-12" v-if="!isMobile() && !loadignquery && products.length == 0">
                              <img :src="'/assets/svg_load/shop-4cards-preloader.svg'" style="width: 100%;" alt="Icono SVG" />
                        </div>
                        <div class="col-12" v-if="!isMobile() && !loadignquery && products.length == 0">
                            <img :src="'/assets/svg_load/shop-4cards-preloader.svg'" style="width: 100%;" alt="Icono SVG" />
                        </div>

                        <div class="col-12" v-if="isMobile() && !loadignquery && products.length == 0">
                              <img :src="'/assets/svg_load/shop-2cards-preloader.svg'" style="width: 100%;" alt="Icono SVG" />
                        </div>
                        <div class="col-12" v-if="isMobile() && !loadignquery && products.length == 0">
                            <img :src="'/assets/svg_load/shop-2cards-preloader.svg'" style="width: 100%;" alt="Icono SVG" />
                        </div>
                          
                      </div>
                    </div>

                    
                  </div>
                </div>

                <div class="col-12 order-1 order-lg-2 px-2 py-2">
                    <PaginationG
                        :pagination="{
                                        per_page: per_page,
                                        total: total,
                                        total_pages: total_pages
                                    }"
                        :maxVisibleButtons="maxVisibleButtons"
                        :current-page="currentPage"
                        @pagechanged="listFilters"/>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Shop Section End -->
      <DetailProduct />
    </div>
</template>
<script>
import myApi from '../../../plugins/axios'
import CardProductA from '../../../components/cards/CardProductA.vue'
import CardKitA from '../../../components/cards/CardKitA.vue'

import PaginationG from '../../PaginationG.vue'
import DetailProduct from "../modal/DetailProduct.vue";
import { debounce } from "debounce";
// import { Carousel, Navigation, Slide } from 'vue3-carousel'
// import 'vue3-carousel/dist/carousel.css'

export default {
    name: 'FilterProduct',
    data(){
        return {
            marca_options: [],
            attributes: [],
            products: [],
            sub_attributes: [],
            search_product: null,
            min_price: null,
            max_price: null,
            // PAGINACIÓN
            currentPage:1,
            per_page:1,
            total:0,
            total_pages:1,
            maxVisibleButtons:3,
            marcas_selected: [],categories_add_selected:[],categoria_options: [],
            // 
            categories: [],
            // 
            categorie_selected: null,
            size_page: 30,
            tag_selected:null,
            // 
            categories_selected: [],
            categorie_g_selected: null,
            sub_categorie_selected: null,
            INDEXBDS: [],
            loadignquery: false,
            // 
            settings: {
              itemsToShow: 1,
              snapAlign: 'center',
            },
            breakpoints: {
              // ,
              300: {
                itemsToShow: 1.5,
                snapAlign: 'center',
              },
              700: {
                itemsToShow: 2.5,
                snapAlign: 'center',
              },
              1024: {
                itemsToShow: 5,
                snapAlign: 'start',
              },
            },
            // 
            kits: [],
        }
    },
    components: {
      CardProductA,
      PaginationG,
      DetailProduct,
      // 
      // Carousel,
      // Slide,
      // Navigation,
      CardKitA,
    },
    props: ['offer_id','slug_product','categorie_id','is_tienda','slug_tags',
      'is_marca_general','marca_id','slug_categorie','categorie_second_id','categorie_third_id',
      "categorie_selected_external","is_best_sellers","show_categorie","show_marca","categorie_s_second_id"
    ],
    methods: {
        minMaskPrice(){
        },
        maxMaskPrice(){
        },
        sizePage(sizePage){
          this.size_page = sizePage;
          this.listFilters();
        },
        tagSelect(TAG){
          this.tag_selected = TAG;
          this.listFilters();
        },
        getAttributeN(attribute_sub){
          let text_before = attribute_sub.s_propiedad == 1 && attribute_sub.value_propiedad ? attribute_sub.value_propiedad :  "";
          let text_after = attribute_sub.s_propiedad == 2 ? attribute_sub.value_propiedad : "";
          return  text_before + " " + attribute_sub.name + " " + text_after ;
        },
        async listKits(){
          let data = {
            // categorie_id: this.categorie_selected,
            categorie_second_id: this.categorie_g_selected ? this.categorie_g_selected : this.categorie_s_second_id,
            categorie_third_id: this.sub_categorie_selected,
          }
          const resp = await myApi.post(
                "ecommerce/kits-list",data
            );
            console.log(resp);
            this.kits = resp.data.kits.data;
            setTimeout(() => {
                this.reloadMyJs()
            }, 25);
        },
        async listFilters(page=1){
            let data = {
                offer_id: this.offer_id,
                search: this.search_product,
                categorie_id: this.categorie_selected,
                //sub_attributes: this.sub_attributes,
                show_marca: this.show_marca,
                min_price: this.min_price,
                max_price: this.max_price,
                marcas_selected: this.marcas_selected,
                categories_add_selected: this.categories_add_selected,show_categorie:this.show_categorie,
                size_page: this.size_page,
                tag_selected: this.tag_selected,
                slug_tags: this.slug_tags,
                // 
                categorie_second_id: this.categorie_g_selected,
                categorie_third_id: this.sub_categorie_selected,
                // 
                is_marca_general: this.is_marca_general,
                //
                marca_id: this.marca_id,
                is_best_sellers: this.is_best_sellers,
            }
            this.currentPage = page;
            this.loadignquery = false;
            const resp = await myApi.post(
                "ecommerce/filters/?page="+this.currentPage,data
            );
            console.log(resp);
            this.products = [];
            this.products = resp.data.products.data.map(product => {
              let OFFER = resp.data.productos_offers.find((item) => item.product_id == product.id);
              if(OFFER){
                product.code_offer = OFFER;
              }
              if(resp.data.categories_offers.length > 0){
                resp.data.categories_offers.forEach(off => {
                  product.code_offer = off;
                });
              }
              return product;
            });
            // console.log(this.products);
            this.loadignquery = true;
            // this.products = resp.data.products.data;
            // this.attributes = resp.data.attributes;
            this.marca_options = resp.data.marca_options.sort(function(a, b) {
                var textA = a.marcas_name.toUpperCase();
                var textB = b.marcas_name.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
            this.categoria_options = resp.data.categories_options.sort(function(a, b) {
                var textA = a.categories_name.toUpperCase();
                var textB = b.categories_name.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
            // STORAGE_ATTR_MARCA
            if(this.categorie_selected){
              // const item = {
              //     value: this.attributes,
              //     expiry: new Date().getTime() + 3600000,
              // }
              // localStorage.setItem('attributes_shop_'+this.categorie_selected, JSON.stringify(item))
              const item2 = {
                  value: this.marca_options,
                  expiry: new Date().getTime() + 3600000,
              }
              localStorage.setItem('marcas_shop_'+this.categorie_selected, JSON.stringify(item2))
              // 
              let local_dinamics = localStorage.getItem("local_dinamic") || JSON.stringify([]);
              local_dinamics = JSON.parse(local_dinamics);
              local_dinamics.push('attributes_shop_'+this.categorie_selected)
              local_dinamics.push('marcas_shop_'+this.categorie_selected)
              localStorage.setItem("local_dinamic",JSON.stringify(local_dinamics))
            }

            this.per_page = resp.data.per_page;
            this.total = resp.data.total;
            this.total_pages = resp.data.total_pages;
            this.maxVisibleButtons = this.total_pages > 4 ? 3 : this.total_pages;

            this.categories_selected = resp.data.categorie;
            if(this.categories_selected && this.categorie_selected){
              this.emitter.emit('categorie-selected', resp.data.categorie_father);
            }
            this.emitter.emit('categorie-selected-add', this.categories_selected);
            setTimeout(() => {
                // this.reloadMyJs()
                this.listKits();
            }, 25);
        },
        async filters_config_all(){
          const resp = await myApi.get(
                "ecommerce/filters_config_all/"
          );
          console.log(resp);
          this.categories = resp.data.categories;
          // this.emitter.emit("categorie-nav-menu",this.categories);
        },
        categorieSeleted(categorie_id){
          this.categorie_g_selected = categorie_id;
          this.sub_categorie_selected = null;
          this.categorie_selected = null;
          this.listFilters();
        },
        subcategorieSeleted(sub_categorie_id){
          this.sub_categorie_selected = sub_categorie_id;
          this.categorie_g_selected = null;
          this.categorie_selected = null;
          this.listFilters();
        },
        changeRange:  debounce(async function(){
          this.min_price = Number(this.$refs.min_price.value) < 0 ? 0 : Number(this.$refs.min_price.value);
          this.max_price = Number(this.$refs.max_price.value) < 0 ? 0 : Number(this.$refs.max_price.value);
          // this.min_price = this.$refs.min_price.value;
          // this.max_price = this.$refs.max_price.value;
          if(this.max_price > 0){
            if(this.min_price >= this.max_price){
              this.min_price = (this.max_price - 1) > -1 ? this.max_price - 1 : 0;
            }
            setTimeout(() => {
              this.listFilters();
            }, 15);
          }
        }, 250),
        // changeRange(){
        // },
        reloadMyJs() {
            var script10 = document.createElement('script');
                script10.src = '../../assets/js/script.js';
                document.body.appendChild(script10);
                script10.onload = () => {
                    console.log('archivo.js cargado 10');
                }    
            const script4 = document.createElement('script');
                script4.src = '../../assets/js/swiper-slider/swiper-custom.min.js';
                document.body.appendChild(script4);
                script4.onload = () => {
                console.log('archivo.js cargado 4');
            }
            const detail_product = document.createElement("script");
            detail_product.src = "../../assets/js/myjs/detailproduct.js";
            document.body.appendChild(detail_product);
            detail_product.onload = () => {
                console.log("archivo.js cargado detail_product");
            };
            const script2 = document.createElement("script");
            script2.src = "../../assets/js/feather/feather.min.js";
            document.body.appendChild(script2);
            script2.onload = () => {
                console.log("archivo.js cargado 2");
            };
            const script6 = document.createElement("script");
            script6.src = "../../assets/js/myjs/loadredirect.js";
            document.body.appendChild(script6);
            script6.onload = () => {
              console.log("archivo.js cargado 7");
            };
        },
        selectedAttributes(subattribute){
            let INDEX = this.sub_attributes.findIndex(item => item == subattribute.id);
            if(INDEX != -1){
                this.sub_attributes.splice(INDEX,1);
            }else{
                this.sub_attributes.push(subattribute.id);
            }
            setTimeout(() => {
                this.listFilters();
            }, 25);
        },
        selectedMarca(marca){
            let INDEX = this.marcas_selected.findIndex(item => item == marca.marcas_id);
            if(INDEX != -1){
                this.marcas_selected.splice(INDEX,1);
            }else{
                this.marcas_selected.push(marca.marcas_id);
            }
            setTimeout(() => {
                this.listFilters();
            }, 25);
        },
        selectedCategorie(categorie){
            let INDEX = this.categories_add_selected.findIndex(item => item == categorie.categories_id);
            if(INDEX != -1){
                this.categories_add_selected.splice(INDEX,1);
            }else{
                this.categories_add_selected.push(categorie.categories_id);
            }
            setTimeout(() => {
                this.listFilters();
            }, 25);
        },
        getWithExpiry(key,val = 0) {
            const itemStr = localStorage.getItem(key)
            if (!itemStr) {
                return val == 0 ? null : [];
            }
            const item = JSON.parse(itemStr)
            const now = new Date()
            if (now.getTime() > item.expiry) {
                localStorage.removeItem(key)
                return val == 0 ? null : [];
            }
            return item.value
        },
        addWishList(product){
            console.log(product);
        },
        goToCompare(product){
            console.log(product);
        },
        openDetailProduct(product){
            console.log(product);
        },
        addCart(product){
            console.log(product);
        },
        isMobile() {
          if(window.innerWidth <= 767 || screen.width <= 767) {
              return true;
          }
          else {
              return false;
          }
        }
    },
    created(){
      this.search_product = this.slug_product;
      this.categorie_selected = this.categorie_id;
      this.categorie_g_selected = this.categorie_second_id;
      this.sub_categorie_selected = this.categorie_third_id;
      this.categories_selected = this.categorie_selected_external;

      this.attributes = this.getWithExpiry('attributes_shop_'+this.categorie_id,1);
      this.marca_options = this.getWithExpiry('marcas_shop_'+this.categorie_id,1);

      this.$store.dispatch('getDats','ecommerce');
    },
    async mounted(){
      this.listFilters()
      // let categories_i = this.$cookies.get("categories_i") ? this.$cookies.get("categories_i") : null;
      this.INDEXBDS = this.$store.state.dats;
      setTimeout(() => {
        let categories_i =  this.INDEXBDS.find((idb) => idb.name == 'categories_i');
        if(categories_i){
          this.categories = JSON.parse(categories_i.data);
          // this.emitter.emit("categorie-nav-menu",this.categories);
        }else{
          this.filters_config_all();
        }
      }, 200);
      
      const script_range_slider = document.createElement("script");
      script_range_slider.src = "../../assets/js/range-slider.js";
      document.body.appendChild(script_range_slider);
      script_range_slider.onload = () => {
          console.log("archivo.js cargado range-slider");
      };
      setTimeout(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
      }, 150);
    }
}
</script>
<style>
.active_categorie{
  background: radial-gradient(circle, rgba(13,72,253,0.6573223039215687) 0%, rgba(8,17,29,0) 61%) !important;
}
.active_categorie .catagories-name{
  color: white !important;
}
</style>