<template>
    <!-- Main Start -->
    <div class="main">
      <section class="page-body p-0">
        <div class="row">
          <div class="order-2 order-lg-1 col-lg-5">
            <div class="content-box">
              <div>
                <h5>¿Has Olvidado tu contraseña? </h5>
                <p class="font-md content-color">Digita tu correo electronico que tengas registrado en la tienda , porque te llegara un codigo.</p>

                <form action="#" class="custom-form form-pill">
                  <div class="input-box1">
                    <label for="email">Email</label>
                    <input class="form-control"  @keyup.enter="forgotPassword()" type="email"  v-model="email" required name="email" id="email" />
                  </div>

                  <button type="button" @click="forgotPassword()" v-if="!loading" class="btn-solid rounded-pill line-none">Enviar Codigo <i class="arrow"></i></button>
                  <div class="loader-5 center" v-if="loading"><span></span></div>
                </form>
                <router-link to="/login" class="backto-link font-default theme-color">Iniciar Sesión</router-link>
              </div>
            </div>
          </div>
          <div class="order-1 order-lg-2 col-lg-7">
            <div class="img-box">
              <img class="bg-img" src="/assets/images/bg/bg-zd-000.jpg" alt="banner" />
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- Main End -->
</template>
<script>
import myApi from '../../../plugins/axios'
export default {
    name:'ForgotPassword',
    data(){
        return {
            email:null,
            loading: false,
            cookie: null,
        }
    },
    methods:{
        async forgotPassword(){
            try {
              if(!this.email){
                this.$notify({
                        title: "Ingresa un dirección de correo electronica.",
                        duration: 3500,
                        type:'error'
                    });
                    return;
              }
              if(!(/^[^@]+@\w+(\.\w+)+\w$/.test(this.email))){
                this.$notify({
                        title: "Ingresa un dirección de correo electronica valida.",
                        duration: 3500,
                        type:'error'
                    });
                    return;
              }
                this.loading = true;
                const resp = await myApi.post(
                    "ecommerce/forgot_password/",
                    {
                        email: this.email
                    }
                );
                this.loading = false;
                if(resp.data.message == 403){
                    this.$notify({
                        title: "VALIDACIÓN",
                        text: `${resp.data.message_text}`,
                        duration: 3500,
                        type:'error'
                    });
                }else{
                    this.$notify({
                        title: "CONTRASEÑA",
                        text: `SE LE ENVIO AL CORREO UN CODIGO DE ACCESO PARA QUE PUEDA RECUPERAR SU CONTRASEÑA`,
                        duration: 3500,
                    });
                    this.$cookies.set("forgot_password",{email: this.email},"30MIN");
                    setTimeout(() => {
                        this.$router.push({
                            name: 'CodeOtp'
                        });
                    }, 50);
                }
                console.log(resp);
            } catch (error) {
                this.$notify({
                    title: "INTERNO",
                    text: `SURGIO UN PROBLEMA EN EL SERVIDOR , COMUNICARSE CON EL SOPORTE DE LA PAGINA`,
                    duration: 3500,
                    type:'error'
                });
                console.log(error);
            }
        }
    },
    created(){
        this.cookie = this.$cookies.get("forgot_password") ? this.$cookies.get("forgot_password") : null;
        if(this.cookie){
            this.$router.push({
                name: 'CodeOtp',
            });
        }
        console.log(this.cookie);
    }
}
</script>