<template>
    <div class="modal view-product fade" id="viewCartDelete" tabindex="-1" aria-labelledby="viewCartDelete" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" style="width: 600px !important;">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="addNewAddressLabel">Condiciones de eliminación </h5>
                <!-- <span class="close-modal" data-bs-dismiss="modal"><i data-feather="x"></i></span> -->
            </div>
            <div class="modal-body">
                <span class="close-modal d-none d-md-block" data-bs-toggle="modal" data-bs-target="#viewCartDelete"><i data-feather="x"></i></span>
                <div class="row gy-4 g-md-0 justify-content-center">
                    <div class="col-12" v-if="cart_selected_modal">
                        <div class="d-flex align-items-center justify-content-center">
                            <label for="acepte_terminos" class="inside mb-0 mx-2" v-if="cart_selected_modal.is_pc_build">
                               Es producto tiene un descuento dado por el pc builder, al eliminarlo tambien se quitaran todos los descuento de los otros productos relacionados.
                            </label>
                            <label for="acepte_terminos" class="inside mb-0 mx-2" v-if="cart_selected_modal.is_kit">
                               Es producto tiene un descuento dado por el kit, al eliminarlo tambien se quitaran todos los descuento de los otros productos relacionados.
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="btn-box">
                    <button type="button" class="btn btn-danger rounded-pill" 
                    data-bs-toggle="modal" data-bs-target="#viewCartDelete" @click="selectedStar(1)">Eliminar</button>

                    <!-- <button type="button" class="btn btn-outline rounded-pill" 
                    data-bs-dismiss="modal" aria-label="Close" @click="selectedStar() ">Cerrar</button> -->
                </div>
            </div>
        </div>
      </div>
    </div>
</template>
<script>
export default {
    name: 'ModalDeleteCart',
    data(){
        return {
            cart_selected_modal:null,
            acepte_terminos:null,
        }
    },
    methods:{
        selectedStar(val = null){
            if(this.cart_selected_modal.is_cart_shop){
                this.emitter.emit("acept-terminos", val);
            }
            if(this.cart_selected_modal.is_header_nav){
                this.emitter.emit("acept-terminos-header", val);
            }
        },
    },
    mounted(){
        this.emitter.on('open-delete-cart',data => {
            this.cart_selected_modal = data;
        });
    }
}
</script>