<template>
    <div class="modal view-product fade" id="viewEntregasEnviosPages" tabindex="-1" aria-labelledby="viewTerminos" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">

                <main class="main">
                    <div class="breadcrumb-wrap">
                        <div class="breadcrumb-wrap">
                            <div class="banner">
                                <img class="bg-img bg-top" src="/assets/images/bg/bg-header-001.jpg" alt="banner" />

                                <div class="container-lg">
                                    <div class="breadcrumb-box">
                                    <div class="heading-box">
                                        <h1> Delivery Info</h1>
                                    </div>
                                    <ol class="breadcrumb">
                                        <li><a href="/">Inicio</a></li>
                                        <li>
                                        <a href="javascript:void(0)"><i data-feather="chevron-right"></i></a>
                                        </li>
                                        <li class="current"><a href="#" onclick="return false;">Delivery Info</a></li>
                                    </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <section class="info-condiciones">
                        <div class="box-condiciones">
                            <div class="img-mascota-box">
                                <img src="https://zonadigitalsv.com/assets/images/brands/zeta-brands.svg" alt="">
                            </div>
                            <div v-html="delivery"></div>
                        </div>
                    </section>
                </main>

            </div>
            <div class="modal-footer">
                <div class="btn-box">
                    <button type="button" class="btn btn-outline rounded-pill" data-bs-dismiss="modal" aria-label="Close">Cerrar</button>
                </div>
            </div>
        </div>
      </div>
    </div>
</template>
<script>
import myApi from "../../../plugins/axios";
export default {
    name: 'ModalPageTerminos',
    data(){
        return {
            delivery:null,
        }
    },
    methods:{
        async listConfiguration(){
            const resp = await myApi.get('ecommerce/config_aviso_politica');
            console.log(resp);
            this.delivery = resp.data.config_general.delivery;
            // this.aviso_marca = resp.data.config_general.aviso_marca;
            // this.garantia = resp.data.config_general.garantia;
            // this.privacidad = resp.data.config_general.privacidad;
        },
    },
    mounted(){
        this.listConfiguration();
    }
}
</script>