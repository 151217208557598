import { createStore } from 'vuex'
import decode from 'jwt-decode'
import { useRouter } from 'vue-router';
import idb from './idb';

const router = useRouter();
// Create a new store instance.
const store = createStore({
  state () {
    return {
      count: 0,
      dats:[],
      // 
      token: localStorage.getItem('token') || null,
      user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
    }
  },
  actions: {
    saveToken({commit},token_user) {
        // console.log(commit);
        this.token = token_user.token;
        this.user = token_user.user;
        commit('setToken', token_user.token)
        commit('setUser', token_user.user)
        if (this.token) {
            localStorage.setItem('token', this.token)
            localStorage.setItem('user', JSON.stringify(this.user))
        }

    },
    authLogin() {
        const token = localStorage.getItem('token')
        if (token) {
            router.push({ name: 'Home' })
        }
    },
    logout({commit}) {
        this.token = null;
        this.user = null;
        commit('setToken', null)
        commit('setUser', null)
        localStorage.removeItem('token')
        localStorage.removeItem('user')
    },
    async deleteDat(context, name='c') {
      await idb.deleteDat(name); 
    },
    async getDats(context,name='c') {
      context.state.dats = [];
      let dats = await idb.getDats(name);
      dats.forEach(c => {
        context.state.dats.push(c);
      });
    },
    async saveDat(context, data) {
      await idb.saveDat(data.dat,data.name);
    }
},
mutations: {
    increment (state) {
        state.count++
    },
    setToken(state, token) {
        state.token = token
    },
    setUser(state, user) {
        state.user = user
    },
},
getters: {
    loggedIn(state) {
        if (state.token) {
            var decode_t = decode(state.token);
            return decode_t&& (decode_t.exp * 1000) > new Date().getTime()
        }
        return false
    },
    // saveCart(data) {
    //     this.emitter.emit('save-cart', data);
    //     return true
    // },
}
})

export default store;