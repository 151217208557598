<template>
     <div class="modal view-product fade" id="viewModalVideo" tabindex="-1" aria-labelledby="viewModalVideo" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" v-if="selected_modal" style="width: auto;">
            <!-- <div class="modal-header p-0">
                <span class="close-modal" data-bs-toggle="modal" data-bs-dismiss="modal"><i data-feather="x"></i></span>
            </div> -->
            <!-- <div class="modal-body"> -->
                <video autoplay controls muted preload="auto" width="640" height="360">
                    <source :src="selected_modal.video" type="video/mp4">
                </video>
            <!-- </div> -->
        </div>
      </div>
    </div>
</template>
<script>
export default {
    name:'VideoProduct',
    data(){
        return {
            selected_modal: null,
        }
    },
    methods:{
    },
    created(){
        
    },
    mounted(){
        this.emitter.on('product-video-modal',data => {
            console.log(data);
            this.selected_modal = null;
            setTimeout(() => {
                this.selected_modal = data;
            }, 50);
            setTimeout(() => {
              const script2 = document.createElement('script');
              script2.src = '../../assets/js/feather/feather.min.js';
              document.body.appendChild(script2);
              script2.onload = () => {
                  console.log('archivo.js cargado 2');
              }
            }, 50);

        });

    }
}
</script>