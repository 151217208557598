<template>
    <div>
        <div class="banner-thank container-lg">
            <div class="container-lg">
                <div class="breadcrumb-box2">
                    <div class="heading-box3">
                        <h3>Sucursales</h3>

                    </div>
                </div>
            </div>
        </div>

        <section>
            <div class="boxes-sucursales container-lg">

                <template v-for="(sucursal, index) in sucursales" :key="index">
                    <div class="sucursal-1">
                        <div class="img-suc">
                            <img :src="sucursal.imagen" alt="">
                        </div>
                        <div class="des-suc">
                            <img :src="sucursal.imagen_add" :alt="sucursal.name" :title="sucursal.name">
                            <h3>{{ sucursal.name }}</h3>
                            <li><img src="/assets/images/Nuevos/location.svg"> <span> {{ sucursal.address }}</span></li>
                            <li>
                                <img src="/assets/images/Nuevos/Phone.svg">
                                <a :href="sucursal.link_phone">{{ sucursal.phone }}</a>
                            </li>
                            <li>
                                <img src="/assets/images/Nuevos/email.svg">
                                <a :href="'mailto:'+sucursal.email"><span>{{ sucursal.email }}</span></a>
                            </li>
                            <li>
                                <img src="/assets/images/Nuevos/hours.svg">
                                Horario:
                                <p>{{ sucursal.horarios }}</p>
                                <p>{{ sucursal.horarios2 }}</p>
                            </li>
                        </div>
                        <div class="iframe-sucursal" v-html="sucursal.gogle_address">
                            
                        </div>
                    </div>
                </template>

            </div>
        </section>
    </div>
</template>
<script>
import myApi from "../../../plugins/axios";
export default {
    name:'SucursalPanel',
    data(){
        return {
            sucursales: [],
        }
    },
    methods:{
        async listConfiguration(){
            const resp = await myApi.get('ecommerce/config_aviso_politica');
            console.log(resp);
            this.sucursales = resp.data.sedes;
            // setTimeout(() => {
            //     this.reloadMyJs();
            // }, 50);
        },
    },
    created(){

    },
    mounted(){
        this.listConfiguration();
    }
}
</script>